import { IStyle } from "office-ui-fabric-react";
import { IAccessRequest } from "./IAccessRequest";
import { IAskAccess } from "./IAskAccess";

export const AccessRequestSchema: IAskAccess = {
	Name: {
		value: "",
		required: true,
		valid: true,
	},
	Id: {
		value: null,
		required: false,
		valid: true,
	},
	AccessRequestId: {
		value: null,
		required: false,
		valid: true,
	},
	EMail: {
		value: "",
		required: true,
		valid: true,
	},
	Company: {
		value: "",
		required: true,
		valid: true,
	},
	Reason: {
		value: "",
		required: true,
		valid: true,
	},
	IsAgreed: {
		value: "No",
		required: true,
		valid: true,
	},
};

export const errorLabelStyles: IStyle = {
	fontFamily: "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
	fontSize: "12px",
	fontWeight: 400,
	color: "rgb(164, 38, 44)",
	margin: "0px",
	paddingTop: "5px",
	display: "flex",
	alignItems: "center",
};
