import { IDetailsColumnStyles, IDetailsListStyleProps, IDetailsListStyles, IStyleFunctionOrObject } from "office-ui-fabric-react";

export const cellStyle: Partial<IDetailsColumnStyles> = {
	cellTitle: {
		fontWeight: 700,
		fontSize: "13px",
		alignItems: "center",
		color: "black",
	},
	cellName: {
		fontWeight: 700,
		wordBreak: "break-word",
		fontSize: "13px",
	},
};

export const detailsListStyle: IStyleFunctionOrObject<IDetailsListStyleProps, IDetailsListStyles> = {
	headerWrapper: {
		position: "sticky",
		top: 0,
		zIndex: 1,
	},
};
