import * as React from "react";
import { Label, Dropdown, IDropdownOption, TextField } from "office-ui-fabric-react";

interface IDocumentSearch {
	brands: IDropdownOption[];
	products: IDropdownOption[];
	types: IDropdownOption[];
	materials: IDropdownOption[];
	selectedBrand: string;
	selectedProduct: string;
	selectedType: string;
	selectedMaterial: string;
	onBrandChange: (value: string) => void;
	onProductChange: (value: string) => void;
	onTypeChange: (value: string) => void;
	onMaterialChange: (value: string) => void;
}

const DocumentSearchFilters = (props: IDocumentSearch) => {
	const {
		brands,
		materials,
		products,
		types,
		selectedBrand,
		selectedProduct,
		selectedType,
		selectedMaterial,
		onBrandChange,
		onProductChange,
		onTypeChange,
		onMaterialChange,
	} = props;
	return (
		<>
			<h3 style={{ fontSize: 20, fontWeight: 600, color: "#605e5c", marginLeft: -15 }}>Documents</h3>
			<div className="row">
				<div className="column2">
					<Label>Material Number</Label>
				</div>
				<div className="column3">
					<TextField value={selectedMaterial} onChange={(e, v) => onMaterialChange(v)} />
				</div>
				<div className="column2"></div>
				<div className="column2">
					<Label>Type</Label>
				</div>
				<div className="column3">
					<Dropdown options={types} selectedKey={selectedType} onChange={(e, op, i) => onTypeChange(op.key as string)} />
				</div>
			</div>
			<div className="row">
				<div className="column2">
					<Label>Brands</Label>
				</div>
				<div className="column3">
					<Dropdown options={brands} selectedKey={selectedBrand} onChange={(e, op, i) => onBrandChange(op.key as string)} />
				</div>
				<div className="column2"></div>
				<div className="column2">
					<Label>Implantline</Label>
				</div>
				<div className="column3">
					<Dropdown options={products} selectedKey={selectedProduct} onChange={(e, op, i) => onProductChange(op.key as string)} />
				</div>
			</div>
		</>
	);
};

export default React.memo(DocumentSearchFilters);
