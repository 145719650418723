import * as React from "react";
import { Navbar } from "react-bootstrap";
import DataProvider from "../../dataprovider/DataProvider";
import { Redirect } from "react-router";
import { Button, Checkbox, css, DefaultButton, Dialog, DialogType, Label, Spinner, SpinnerSize, Stack } from "office-ui-fabric-react";
import { loadGapiInsideDOM, loadAuth2 } from "gapi-script";
import { MsalContext } from "@azure/msal-react";
import { IAuthenticatedUser, LoginComponent } from "./LoginComponent";

export interface IExternalConsentProps {
	setAuthStatus: (loggedIn: boolean, user: IAuthenticatedUser) => Promise<void>;
	loginComponent: LoginComponent;
}

export interface IExternalConsentState {
	legalNoticeChecked: boolean;
	privacyNoticeChecked: boolean;
	redirectWelcome: boolean;
	user: IAuthenticatedUser;
	showWaitingSpinner: boolean;
}

export class ExternalConsent extends React.Component<IExternalConsentProps, IExternalConsentState> {
	static contextType = MsalContext;

	constructor(props: IExternalConsentProps) {
		super(props);

		this.state = {
			legalNoticeChecked: false,
			privacyNoticeChecked: false,
			redirectWelcome: false,
			user: null,
			showWaitingSpinner: false,
		};
	}

	public componentDidMount() {
		this.setState({
			user: JSON.parse(window.sessionStorage?.rsmConsentTarget),
		});
	}

	private onLegalNoticeClicked = (ev: any, checked?: boolean) => {
		this.setState({ legalNoticeChecked: checked });
	};

	private onPrivacyNoticeClicked = (ev: any, checked?: boolean) => {
		this.setState({ privacyNoticeChecked: checked });
	};

	private onConfirmClicked = () => {
		console.log("MSAL Context", this.context);
		this.setState({ showWaitingSpinner: true });
		window.sessionStorage.removeItem("rsmConsentTarget");

		this.props.loginComponent
			.ensureSPUser(this.state.user, this.state.user.bearerToken.token, true)
			.then((finalUser) => {
				window.sessionStorage.authToken = JSON.stringify(finalUser.bearerToken);
				window.sessionStorage.stlUser = JSON.stringify(finalUser);

				this.props.setAuthStatus(true, finalUser);

				this.setState({ redirectWelcome: true, showWaitingSpinner: false });
			})
			.catch((err) => {
				let error = err;
				if (err.message) {
					error = err.message;
				}
				alert("Error: " + error);
			});
	};

	private onNoConsentClicked = () => {
		var that = this;

		// sign out of google, if it's a google account
		try {
			loadGapiInsideDOM().then((gapi) => {
				console.log("Gapi loaded....", gapi);

				gapi.load("auth2", function () {
					console.log("auth2 loaded", (window as any).gapi.auth2);
					gapi.auth2.getAuthInstance().then((auth2: any) => {
						auth2.signOut().then(() => {
							console.log("signed out.");
							that.setState({ redirectWelcome: true });
						});
					});
				});
			});
		} catch (err) {
			/* ignore possible errors */
		}

		// sign out of microsoft, if it's a microsoft account
		try {
			this.context?.instance?.logoutRedirect({
				onRedirectNavigate: (url: string) => {
					// Return false if you would like to stop navigation after local logout
					return false;
				},
			});
		} catch (err) {
			/* ignore possible errors*/
		}

		window.sessionStorage.removeItem("rsmConsentTarget");
	};

	public render(): React.ReactElement<IExternalConsentProps> {
		return (
			<div>
				{this.state.redirectWelcome && <Redirect to={"/"} />}
				<div>
					Hello {this.state?.user?.firstName} {this.state?.user?.lastName},<br />
					<br />
					This is the first time you are accessing the Straumann Research Screening Site with your {this.state?.user?.accountType} account.
					<br />
					In order to be able to access this site we need the following consent from you.
				</div>
				<Stack>
					<Stack style={{ marginTop: "15px" }}>
						<Stack horizontal verticalAlign="center">
							<Checkbox id="chkLegal" onChange={this.onLegalNoticeClicked} checked={this.state?.legalNoticeChecked} />
							<Label htmlFor="chkLegal" style={{ position: "relative", top: "-5px" }}>
								I agree to the{" "}
								<a target="_blank" href="https://www.straumann.com/en/dental-professionals/legal-notice.html">
									StraumannGroup's Legal Notice
								</a>
							</Label>
						</Stack>
					</Stack>
					<Stack>
						<Stack horizontal verticalAlign="center">
							<Checkbox id="chkPrivacy" onChange={this.onPrivacyNoticeClicked} checked={this.state?.privacyNoticeChecked} />
							<Label htmlFor="chkPrivacy" style={{ position: "relative", top: "-5px" }}>
								I acknowledge that my data will be processed in accordance with the{" "}
								<a target="_blank" href="https://www.straumann.com/group/en/discover/privacy/com/en.html">
									StraumannGroup's Privacy Notice
								</a>
							</Label>
						</Stack>
					</Stack>
					<Stack style={{ marginTop: "20px" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<DefaultButton
								style={{
									color: "white",
									backgroundColor: !(this.state.legalNoticeChecked && this.state.privacyNoticeChecked) ? "gray" : "green",
									cursor: !(this.state.legalNoticeChecked && this.state.privacyNoticeChecked) ? "not-allowed" : "pointer",
								}}
								text={"Confirm"}
								onClick={this.onConfirmClicked}
								disabled={!(this.state.legalNoticeChecked && this.state.privacyNoticeChecked)}
							/>
							<DefaultButton style={{ color: "white", backgroundColor: "red" }} text={"I do not Consent"} onClick={this.onNoConsentClicked} />
						</div>
					</Stack>
				</Stack>
				<Dialog
					hidden={!this.state.showWaitingSpinner}
					dialogContentProps={{
						type: DialogType.normal,
					}}
					modalProps={{ isBlocking: true, className: "busyOverlay" }}
				>
					<Spinner size={SpinnerSize.large} label="Working on it..." ariaLive="assertive" />
				</Dialog>
			</div>
		);
	}
}
