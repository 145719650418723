import * as React from "react";
import { css, Stack, PrimaryButton, IStackTokens } from "office-ui-fabric-react";
import { PageURL } from "../../App";
import "./Welcome.scss";
import { IUserAccount } from "../LoginComponent/LoginComponent";
import DataProvider from "../../dataprovider/DataProvider";

const sectionStackTokens: IStackTokens = { childrenGap: 10 };
export interface IWelcomePageProps {
	location: Location;
	history: any;
	stepSizeClass: string;
	isLoggedIn: boolean;
	dataProvider: DataProvider;
	currentUser?: IUserAccount;
}

export interface IWelcomePageState {
	isLoggedIn: boolean;
	myItems?: any[];
	isLoading: boolean;
	isDeleteConfirmationOpen: boolean;
	selectedItem: any;
	isDeleting: boolean;
	currentRequestStatus: string;
	canCreateNewRequest: boolean;
	showInReviewMessage: boolean;
}

export class WelcomePage extends React.Component<IWelcomePageProps, IWelcomePageState> {
	constructor(props: IWelcomePageProps) {
		super(props);

		this.state = {
			isLoggedIn: props.isLoggedIn,
			myItems: [],
			isLoading: false,
			isDeleteConfirmationOpen: false,
			selectedItem: null,
			isDeleting: false,
			currentRequestStatus: "",
			canCreateNewRequest: false,
			showInReviewMessage: false,
		};
		//this.setLoggedInInformation();
	}
	public componentDidMount() {
		this.setLoggedInInformation();
	}

	public componentWillReceiveProps(newProps: IWelcomePageProps) {
		if (this.state.isLoggedIn != newProps.isLoggedIn) {
			this.setState({ isLoggedIn: newProps.isLoggedIn }, () => {
				this.setLoggedInInformation();
			});
		}
	}

	private async setLoggedInInformation() {
		if (this.state.isLoggedIn && this.props.currentUser) {
			let canCreateNewRequest = false;
			let currentRequestStatus = "";
			this.setState({ isLoading: true });
			const response: any = await this.props.dataProvider.getUserData(this.props.currentUser.userId);

			if (response) {
				currentRequestStatus = response["currentRequestStatus"] ? response["currentRequestStatus"] : "";
				canCreateNewRequest = response["canCreateNewRequest"] ? response["canCreateNewRequest"] : false;

				// if (response["currentRequestStatus"] == "AskForAccess") {
				// 	this.props.history.push(PageURL.AskAccess);
				// }
				if (currentRequestStatus == "AccessApproved") {
					this.props.history.push(PageURL.Download);
				} else if (currentRequestStatus == "AccessDenied") {
					this.setState({
						currentRequestStatus: response["currentRequestStatus"],
						canCreateNewRequest: true,
						showInReviewMessage: false,
					});
				} else if (!canCreateNewRequest && currentRequestStatus == "DocumentSubmitted") {
					this.setState({
						currentRequestStatus: response["currentRequestStatus"],
						canCreateNewRequest: response["canCreateNewRequest"],
						showInReviewMessage: true,
					});
				} else if (!canCreateNewRequest && currentRequestStatus == "AskForAccess") {
					this.props.history.push(PageURL.SubmitAgreement + "/" + this.props.currentUser.userId);
				} else {
					this.setState({
						currentRequestStatus: response["currentRequestStatus"],
						canCreateNewRequest: response["canCreateNewRequest"],
						showInReviewMessage: false,
					});
				}
			}
		} else {
			this.setState({ myItems: [] });
		}
	}

	public render(): React.ReactElement<IWelcomePageProps> {
		return (
			<>
				{!this.state.showInReviewMessage && (
					<Stack verticalAlign="center" className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>
						{/* <Stack horizontal className="fullWidth" horizontalAlign="center">
							<h3>Software provider documents</h3>
						</Stack> */}

						<Stack
							verticalAlign="space-between"
							horizontalAlign="start"
							className={css("welcomeContainer", this.props.stepSizeClass, "animate", "popIn")}
							tokens={{ childrenGap: 5 }}
						>
							<h4>Welcome to the Straumann STL Library!</h4>
							<p style={{ paddingRight: 20 }}>
								This library was created by the Straumann Group as a service for providers of third-party planning software to download STL files of Straumann
								group implants, guided sleeves and scanbodies to include in their planning software.
							</p>
							<p>
								Kindly sign in or create a new account.
								<ul>
									<li>If you are creating a new account, you will be sent an activation confirmation email (look for an email from app_azure@straumann.com)</li>
									<li>
										Once you have confirmed your account creation, you will be instructed to request access by providing your contact details and downloading,
										completing, signing and then uploading the mandatory legal agreement form
									</li>
									<li>
										You will be notified by email once your request has been approved, after which you can freely download all required all available STL files.
									</li>
								</ul>
							</p>
							<p>
								<img src={require("./../../images/K0006-03_V01-Straumann Surgical - Implants_RGB_Transparent.png")} style={{ width: "75%" }} />
							</p>
							<Stack className="fullWidth" horizontalAlign="center">
								{!this.state.isLoggedIn ? (
									<Stack horizontal className="fullWidth" horizontalAlign="center">
										<div style={{ fontWeight: "bold" }}>Please sign in to be able to create access request</div>
									</Stack>
								) : (
									<>
										{this.state.canCreateNewRequest && (
											<Stack horizontal className="fullWidth" tokens={sectionStackTokens}>
												<PrimaryButton
													text="Ask for access"
													styles={{ root: { height: 38 } }}
													onClick={() => {
														this.props.history.push(PageURL.AskAccess);
													}}
												/>
											</Stack>
										)}
									</>
								)}
							</Stack>
						</Stack>
					</Stack>
				)}
				{this.state.showInReviewMessage && (
					<Stack verticalAlign="space-between" horizontalAlign="start" className={css("welcomeContainer")} tokens={{ childrenGap: 5 }}>
						Your request is currently in review state
					</Stack>
				)}
			</>
		);
	}
}
