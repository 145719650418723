import * as React from "react";
import { css, Stack, Dialog, DialogType, Spinner, SpinnerSize } from "office-ui-fabric-react";
import { IUserAccount } from "../LoginComponent/LoginComponent";
import { IAccessRequest } from "../../Models/IAccessRequest";
import "./../../Styles/Access.scss";

import ReCAPTCHA from "react-google-recaptcha";

import { cloneDeep } from "lodash";
import { AccessRequestSchema } from "../../Models/Constant";
import { IDataProvider } from "../../dataprovider/IDataProvider";
import { IAskAccess } from "../../Models/IAskAccess";
import { RequesterData } from "../RequesterData/RequesterData";
import { CaptchaAndSubmitAction } from "../RequestCaptchaAndSubmitAction/RequestCaptchaAndSubmitAction";
import { PageURL } from "../../App";
export interface IAskAccessProps {
	location: Location;
	history: any;
	stepSizeClass: string;
	isLoggedIn: boolean;
	dataProvider: IDataProvider;
	currentUser?: IUserAccount;
}

export interface IAskAccessState {
	isLoggedIn: boolean;
	data: any;
}

export const AskAccess = (props: IAskAccessProps) => {
	const [data, setData] = React.useState<IAskAccess>(AccessRequestSchema);
	const [reCaptchaToken, setReCaptchaToken] = React.useState<string>("");
	const [isCaptchaValid, setIsCaptchaValid] = React.useState<boolean>(true);
	const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
	const [canCreateNewRequest, setCanCreateNewRequest] = React.useState<boolean>(false);
	const [showInReviewMsg, setShowInReviewMsg] = React.useState<boolean>(false);
	const refReCaptchaRegister = React.createRef<ReCAPTCHA>();
	const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
	const [showInSubmissionMessage, setShowInSubmissionMessage] = React.useState<boolean>(false);
	React.useEffect(() => {
		(async () => {
			const dataClone: IAskAccess = cloneDeep(data);
			const firstName = props.currentUser.firstName || "";
			const lastName = props.currentUser.lastName || "";
			dataClone.Name.value = firstName + " " + lastName;
			dataClone.EMail.value = props.currentUser.email || "";
			let canCreateNewRequest = false;
			let showInReviewMsg = false;
			let currentRequestStatus = "";
			const response: any = await props.dataProvider.getUserData(props.currentUser.userId);

			if (response) {
				canCreateNewRequest = response["canCreateNewRequest"] ? response["canCreateNewRequest"] : false;
				currentRequestStatus = response["currentRequestStatus"] ? response["currentRequestStatus"] : "";
			}

			if (currentRequestStatus == "AccessApproved") {
				props.history.push(PageURL.Download);
			}

			if (!canCreateNewRequest && currentRequestStatus == "DocumentSubmitted") {
				showInReviewMsg = true;
			}
			if (!canCreateNewRequest && currentRequestStatus == "AskForAccess") {
				props.history.push(PageURL.SubmitAgreement + "/" + props.currentUser.userId);
			}
			setData(dataClone);
			setCanCreateNewRequest(canCreateNewRequest);
			setShowInReviewMsg(showInReviewMsg);
		})();
	}, []);

	const handleTextFieldChange = (fieldName: string, value: string) => {
		const dataClone: any = cloneDeep(data);
		dataClone[fieldName].value = value;
		setData(dataClone);
	};

	const handleCheckBoxChange = (fieldName: string, value: boolean) => {
		const dataClone: any = cloneDeep(data);
		if (value) {
			dataClone[fieldName].value = "Yes";
		} else {
			dataClone[fieldName].value = "";
		}
		setData(dataClone);
	};

	const validateFormFields = () => {
		setIsProcessing(true);

		let formValid = true;
		let captchaValid = true;
		const dataClone: any = cloneDeep(data);
		Object.keys(dataClone).forEach((field) => {
			if (dataClone[field].required) {
				if (dataClone[field].hasOwnProperty("value") && dataClone[field].value != null && dataClone[field].value != "") {
					dataClone[field].valid = true;
				} else {
					dataClone[field].valid = false;
					formValid = false;
				}
			}
		});
		if (reCaptchaToken == "") {
			captchaValid = false;
			formValid = false;
		}
		setData(dataClone);
		if (!formValid) {
			setIsProcessing(false);
		}

		setIsCaptchaValid(captchaValid);
		return formValid;
	};

	const onRegisterBtnCLicked = () => {
		setShowSpinner(true);
		const isFormValid = validateFormFields();
		if (isFormValid) {
			const accessRequestData: Partial<IAccessRequest> = {};
			const dataClone: IAskAccess = cloneDeep(data);
			Object.keys(dataClone).forEach((field) => {
				if (field != "FirstName" && field != "LastName") {
					(accessRequestData as any)[field] = (dataClone as any)[field].value;
				}
			});
			accessRequestData.Name = dataClone.Name.value;
			accessRequestData.UserId = props.currentUser.userId;
			props.dataProvider
				.createAccessRequest(accessRequestData)
				.then(async (result: any) => {
					setShowSpinner(false);
					setCanCreateNewRequest(false);
					setShowInReviewMsg(false);
					setShowInSubmissionMessage(true);
				})
				.catch((error) => {
					console.log(error);
					setCanCreateNewRequest(true);
					setShowInReviewMsg(false);
					setShowSpinner(false);
				});
		} else {
			setShowSpinner(false);
			setCanCreateNewRequest(true);
		}
	};

	return (
		<>
			{canCreateNewRequest && (
				<Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
					<Dialog
						hidden={!showSpinner}
						dialogContentProps={{
							type: DialogType.normal,
						}}
						modalProps={{ isBlocking: true, className: "busyOverlay" }}
					>
						<Spinner size={SpinnerSize.large} label="Working on it..." ariaLive="assertive" />
					</Dialog>
					<RequesterData data={data} onCheckBoxChange={handleCheckBoxChange} onTextFieldChange={handleTextFieldChange} readonly={false}></RequesterData>

					<CaptchaAndSubmitAction
						onRecaptchaToken={setReCaptchaToken}
						onButtonClicked={onRegisterBtnCLicked}
						refReCaptchaRegister={refReCaptchaRegister}
						isCaptchaValid={isCaptchaValid}
						isSubmitProcessing={isProcessing}
						buttonTitle="Send me the agreement"
					/>
				</Stack>
			)}
			<>
				{showInReviewMsg && (
					<Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
						<div style={{ paddingTop: "20%", textAlign: "center" }}>Your current request is in review state</div>
					</Stack>
				)}
			</>
			<>
				{showInSubmissionMessage && (
					<Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
						<div style={{ paddingTop: "20%", textAlign: "center" }}>
							Request submitted successfully. It can take a moment untill you receive the email with the agreement attached.
						</div>
					</Stack>
				)}
			</>
		</>
	);
};
