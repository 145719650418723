import * as React from "react";
import { css, Stack, PrimaryButton, Text, getTheme, Link, Spinner } from "office-ui-fabric-react";
import { IDataProvider } from "../../dataprovider/IDataProvider";
import { GeneralHelper } from "./LoginHelper";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib-commonjs";
import { Common } from "./Common";
const theme = getTheme();

export interface IConfirmPageProps {
	location: Location;
	history: any;
	stepSizeClass: string;
	dataProvider: IDataProvider;
}

export interface IConfirmPageState {
	activationInProgress: boolean;
	accountActivated: boolean;
	errorMessage: string;

	sendingConfirmation: boolean;
}

export class ConfirmPage extends React.Component<IConfirmPageProps, IConfirmPageState> {
	constructor(props: IConfirmPageProps) {
		super(props);

		this.state = {
			activationInProgress: true, // activation starts on page load
			accountActivated: false,
			errorMessage: "",

			sendingConfirmation: false,
		};
	}
	public componentDidMount() {
		console.log("ConfirmPage componentDidMount");
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const code = urlParams.get("activationGuid");
		if (code) {
			this.props.dataProvider
				.getUserByActivateParam(code, true)
				.then((result) => {
					this.setState({ accountActivated: true, activationInProgress: false });
				})
				.catch((err) => {
					console.dir(err);
					let errormessage = err;
					if (err.detail) {
						errormessage = err.detail;
					}
					this.setState({ activationInProgress: false, errorMessage: errormessage });
				});
		} else {
			this.setState({ errorMessage: "No valid Activation url", activationInProgress: false });
		}
	}

	public render(): React.ReactElement<IConfirmPageProps> {
		return (
			<Stack horizontal verticalAlign="center" className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>
				<Stack
					verticalAlign="space-between"
					horizontalAlign="center"
					className={css("subscriptionContainerInner", this.props.stepSizeClass, "animate", "popIn")}
					tokens={{ childrenGap: 5 }}
				>
					<h2>User Account Activation</h2>
					{this.state.activationInProgress ? <Spinner label="Activating account..." /> : null}
					{this.state.accountActivated ? (
						<MessageBar
							messageBarType={MessageBarType.success}
							onDismiss={(ev?: any) => {
								window.location.href = `${Common.getCurrentServerUrl()}/`;
							}}
						>
							User activated successfully! You can log in now
						</MessageBar>
					) : (
						this.state.errorMessage && (
							<MessageBar messageBarType={MessageBarType.error}>
								Activation was not successful. This activation code might have expired.
								<br />
								<a href="/resetPW?confirmOnly=true">Click here</a> to order a new activation code
							</MessageBar>
						)
					)}
				</Stack>
			</Stack>
		);
	}
}
