import React, { Component } from 'react';
import { Redirect, Route } from 'react-router';
import { Layout } from './components/Layout/Layout';

import { WelcomePage } from './components/Welcome/Welcome';
import { AskAccess } from './components/AskAccess/AskAccess';
import { LoginComponent } from './components/LoginComponent/LoginComponent';
import { ConfirmPage } from './components/LoginComponent/ConfirmPage';
import { ResetPWPage } from './components/LoginComponent/ResetPWPage';
import { ExternalConsent } from './components/LoginComponent/ExternalConsent';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Common } from './components/LoginComponent/Common';
import {SubmitAgreement} from './components/SubmitAgreement/SubmitAgreement';
import {DocumentSearchAndDownload} from './components/DocumentSearchAndDownload/DocumentSearchAndDownload';
import './custom.scss'
import DataProvider from './dataprovider/DataProvider';

import { CookieList } from './components/LoginComponent/CookieList';


export const PageURL = {
   
    ConfirmationPage: "/confirm",
    ResetPWPage: "/resetPW",
    Consent: "/consent",
    CookieList: "/cookies",
    AskAccess:"/AskAccess",
    SubmitAgreement:"/SubmitAgreement",
    Download:"/Download"
}


function getMSALConfig(){
    let returnURI = Common.getCurrentServerUrl();
  
    return  {
        auth: {
            clientId: "616b6cae-69cb-4a9b-9674-d6ab4ef29376",
            authority: "https://login.microsoftonline.com/common",
            redirectUri: `${returnURI}`
        },
        cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        }
    };
}

const msalInstance = new PublicClientApplication(getMSALConfig());


export default class App extends Component {
    refLoginComponent = null;

    constructor() {
        super();
        this.state = {
            isLoggedIn: false,
            currentUser: null,
            path: "/",
            redirectHome: false
        };

        this.refLoginComponent = React.createRef();
    }    

    componentDidMount() {
        // in case the page is reloaded, check if there is a user in the session already
        if (window.sessionStorage.authToken) {
            let authToken = JSON.parse(window.sessionStorage.authToken);
            let expirationDate = new Date(authToken.expirationUTC);
            if (expirationDate < new Date()) {
                // this token is expired. Clean it up
                window.sessionStorage.removeItem("authToken");
                window.sessionStorage.removeItem("stlUser");
                alert("Your Session expired. Please sign-in again.");
            }
            if (window.sessionStorage.stlUser) {
                let currentUser = JSON.parse(window.sessionStorage.stlUser);
                this.setState({
                    isLoggedIn: true,
                    currentUser: currentUser
                });
            }
        }
    }

    setAuthStatus = (loggedIn, user) => {

        return new Promise((resolve, reject) => { 
             if (!user) {
                    sessionStorage.removeItem("authToken");
                    sessionStorage.removeItem("stlUser");
                    console.log("New Url: "+`${window.location.protocol}//${window.location.host}`);
                    window.location.href = `${window.location.protocol}//${window.location.host}`;

                }
            this.setState({ isLoggedIn: loggedIn, currentUser: user })
            resolve()
            console.log("%cCurrent User", "color:blue;font-size:16px", user);
        });
    }

    sessionExpired = () => {
        this.setState({
            isLoggedIn: false,
            currentUser: null
        });
        window.sessionStorage.removeItem("authToken");
        window.sessionStorage.removeItem("stlUser");
        alert("Your Session expired. Please sign-in again.");
    };

    static displayName = App.name;

    dataProvider = new DataProvider(); 


    render() {
        return (            
            <Layout>
                {this.state.redirectHome && <Redirect to="/"/>}
                <MsalProvider instance={msalInstance}>
                    <Route exact path='/' render={(props) => {
                        if(this.state.path !== "/")
                            this.setState({ path: "/" });
                        return <WelcomePage {...props} dataProvider={this.dataProvider} currentUser={this.state?.currentUser} isLoggedIn={this.state.isLoggedIn} />;
                            
                    }} />  
                    <Route exact
                        path={PageURL.ConfirmationPage}
                        render={(props) => {
                            if (this.state.path !== PageURL.ConfirmationPage)
                                this.setState({ path: PageURL.ConfirmationPage });
                            return <ConfirmPage {...props} dataProvider={this.dataProvider} />;
                        }}
                    />                  
                     <Route exact
                        path={PageURL.SubmitAgreement+"/:id"}
                        render={(props) => {                          
                           return <SubmitAgreement   {...props}  dataProvider={this.dataProvider}/>
                        }}
                    />
                    <Route exact
                        path={PageURL.ResetPWPage}
                        render={(props) => {
                            if (this.state.path !== PageURL.ResetPWPage)
                                this.setState({ path: PageURL.ResetPWPage });
                            return <ResetPWPage {...props} dataProvider={this.dataProvider} />;
                        }}
                    />
                        <Route exact
                        path={PageURL.AskAccess}                    
                        render={(props) => (                            
                            <>
                            {                                 
                                    this.state.isLoggedIn && <AskAccess sessionExpired={this.sessionExpired } currentUser={this.state?.currentUser} {...props} isLoggedIn={this.state.isLoggedIn} dataProvider={this.dataProvider}/>
                                    
                            }                        
                            </>
                        )}
                        />
                         <Route exact
                        path={PageURL.Download}                    
                        render={(props) => (                            
                            <>
                            {                                 
                                    this.state.isLoggedIn && <DocumentSearchAndDownload sessionExpired={this.sessionExpired } currentUser={this.state?.currentUser} {...props} isLoggedIn={this.state.isLoggedIn} dataProvider={this.dataProvider}/>
                                    
                            }                        
                            </>
                        )}
                        />
                    <Route exact
                        path={PageURL.Consent}
                        render={(props) => {
                            if (this.state.path !== PageURL.Consent)
                                this.setState({ path: PageURL.Consent });
                            return <ExternalConsent setAuthStatus={this.setAuthStatus} loginComponent={this.refLoginComponent.current} {...props} />;
                        }
                        }/>
                    <Route exact 
                        path={PageURL.CookieList} 
                        render={(props)=>{
                            return <CookieList {...props} />;
                        }}
                        />
                    <div style={{ height: '100%' }}><LoginComponent key={this.state.path} ref={ this.refLoginComponent } setAuthStatus={this.setAuthStatus} currentUser={this.state?.currentUser} /></div>
                </MsalProvider>
            </Layout>            
        );
    }
}
