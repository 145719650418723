import * as React from "react";
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, IconButton, IIconProps, Modal, SelectionMode } from "office-ui-fabric-react";
import { cancelIcon, iconButtonStyles, contentStyles } from "./Constant";
import { cellStyle } from "../Constant";

export interface IVersionHistoryProps {
	isModalOpen: boolean;
	onDismissModal: () => void;
	allItems: any[];
	downloadedItems: any[];
	currentItem: any;
}
export const VersionHistory = (props: IVersionHistoryProps) => {
	const [versionHistory, setVersionHistory] = React.useState<any[]>([]);

	React.useEffect(() => {
		if (props.isModalOpen) {
			const relatedItems = props.allItems.filter((value) => value["Material"] == props.currentItem["Material"]);
			const relevantItems = relatedItems.filter((value) => value["UniqueId"] != props.currentItem["UniqueId"]);

			let history: any[] = [];

			relatedItems.forEach((value, index) => {
				const historyRecord: any = {};
				const isDownloaded = props.downloadedItems.findIndex((dItem) => dItem["FileUniqueId"] == value["UniqueId"]);
				if (isDownloaded == -1) {
					historyRecord.FileVersion = value["FileVersion"];
					historyRecord.Downloaded = "No";
				} else {
					historyRecord.FileVersion = value["FileVersion"];
					historyRecord.Downloaded = "Yes";
				}
				history.push(historyRecord);
			});
			setVersionHistory(history);
		}
	}, [props]);
	return (
		<Modal
			isOpen={props.isModalOpen}
			onDismiss={props.onDismissModal}
			isBlocking={true}
			styles={{ scrollableContent: { overflow: "hidden", maxHeight: "auto" } }}
		>
			<div className={contentStyles.header}>
				<span>Version History</span>
				<IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close version history" onClick={() => props.onDismissModal()} />
			</div>
			<div className={contentStyles.body}>
				<DetailsList
					columns={viewColumnsSchema}
					items={versionHistory}
					layoutMode={DetailsListLayoutMode.justified}
					selectionMode={SelectionMode.none}
					disableSelectionZone={true}
					constrainMode={ConstrainMode.unconstrained}
					isHeaderVisible={true}
					selectionPreservedOnEmptyClick={false}
				></DetailsList>
			</div>
		</Modal>
	);
};

const viewColumnsSchema: IColumn[] = [
	{
		key: "FileVersion",
		name: "File Version",
		fieldName: "FileVersion",
		minWidth: 150,
		maxWidth: 200,
		styles: cellStyle,
	},
	{
		key: "Downloaded",
		name: "Downloaded",
		fieldName: "Downloaded",
		minWidth: 100,
		maxWidth: 200,
		styles: cellStyle,
	},
];
