import { Checkbox, DefaultButton, IColumn, PrimaryButton, Stack } from "office-ui-fabric-react";
import * as React from "react";
import { DocumentsSearchAndDownloadService } from "../../DocumentSearchAndDownloadService";

interface IFilterPanelProps {
	data: any[];
	filterBy: IColumn;
	selectedFilterOptions: any[];
	onCancel: () => void;
	onApply: (filterValues: string[]) => void;
}

const filterStackTokens = { childrenGap: 10 };
const buttonStackTokens = { childrenGap: 10 };

export const FilterPanel = (props: IFilterPanelProps) => {
	const [filterByValues, setFilterByValues] = React.useState([]);
	const [selectedFilterValues, setSelectedFilterValues] = React.useState<string[]>([]);
	const [isApplyButtonEnabled, setIsApplyButtonEnabled] = React.useState(false);

	const isApplyButtonEnabledonLoad = React.useRef<boolean>(false);
	React.useEffect(() => {
		(async () => {
			let enableApplyButton = false;
			const response: any = DocumentsSearchAndDownloadService.getValuesForFilterByOption(props.data, props.filterBy);
			let prevFilterValues: string[] = [];
			if (props.selectedFilterOptions) {
				if (props.selectedFilterOptions.length > 0) {
					props.selectedFilterOptions.forEach((option) => {
						if (option.column == props.filterBy.fieldName) {
							option.values.forEach((value: any) => {
								prevFilterValues.push(value);
							});
						}
					});
				}
			}
			if (prevFilterValues.length > 0) {
				enableApplyButton = true;
			}
			setFilterByValues(response);
			setSelectedFilterValues(prevFilterValues);
			isApplyButtonEnabledonLoad.current = enableApplyButton;
			setIsApplyButtonEnabled(enableApplyButton);
		})();
	}, []);

	const handleCheckBoxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean, f?: string) => {
		const selectedFilterValuesClone = [...selectedFilterValues];
		let enableApplyButton = false;
		if (checked) {
			selectedFilterValuesClone.push(f);
			enableApplyButton = true;
		} else {
			const index = selectedFilterValuesClone.indexOf(f, 0);
			if (index > -1) {
				selectedFilterValuesClone.splice(index, 1);
			}
			if (isApplyButtonEnabledonLoad.current) {
				enableApplyButton = true;
			} else {
				if (selectedFilterValuesClone.length == 0) {
					enableApplyButton = false;
				} else {
					enableApplyButton = true;
				}
			}
		}

		setSelectedFilterValues(selectedFilterValuesClone);
		setIsApplyButtonEnabled(enableApplyButton);
	};

	const handleApplyAction = () => {
		props.onApply(selectedFilterValues);
	};

	const handleClearAll = () => {
		let enableApplyButton = false;

		if (isApplyButtonEnabledonLoad.current) {
			enableApplyButton = true;
		}

		setSelectedFilterValues([]);
		setIsApplyButtonEnabled(enableApplyButton);
	};

	return (
		<>
			{filterByValues != undefined ? (
				<>
					<div style={{ marginTop: 20 }}>
						<Stack tokens={filterStackTokens}>
							{filterByValues.map((f) => {
								return (
									<Checkbox
										label={f != "" && f != undefined && f != null ? f : "Empty"}
										checked={selectedFilterValues.length > 0 && selectedFilterValues.indexOf(f) > -1 ? true : false}
										onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f)}
										key={f}
									/>
								);
							})}
						</Stack>
					</div>
					<div style={{ marginTop: 20 }}>
						<Stack horizontal tokens={buttonStackTokens}>
							<PrimaryButton
								text="Apply"
								styles={{ root: { backgroundColor: "green" } }}
								disabled={isApplyButtonEnabled ? false : true}
								onClick={handleApplyAction}
							></PrimaryButton>
							<DefaultButton text="Clear All" onClick={handleClearAll}></DefaultButton>
						</Stack>
					</div>
				</>
			) : null}
		</>
	);
};
