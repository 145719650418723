import { IDataProvider } from "./IDataProvider";
import HttpService from "./HttpService";
import { IAuthenticatedUser, IUserAccount } from "../components/LoginComponent/LoginComponent";
import { Common } from "../components/LoginComponent/Common";
import { IAccessRequest } from "../Models/IAccessRequest";
export default class DataProvider implements IDataProvider {
	public apiEndpointSP = `/api/SPInterface`;
	public userAccountEndpointSP = `/api/UserAccountSPInterface`;
	public async getAccessRequest(userId: string): Promise<any> {
		const accessRequest = await HttpService.post(`${this.apiEndpointSP}/GetAccessRequest`, { userId: userId });
		return accessRequest;
	}
	public async getAccessRequestForAgreementSubmission(userId: string): Promise<any> {
		const accessRequest = await HttpService.post(`${this.apiEndpointSP}/GetAccessRequestForAgreementSubmission`, { userId: userId });
		return accessRequest;
	}

	public async createAccessRequest(accessRequest: IAccessRequest): Promise<any> {
		const response = await HttpService.post(`${this.apiEndpointSP}/CreateAccessRequest`, accessRequest);
		return response;
	}

	public async getUserData(userId: string): Promise<any> {
		const response = await HttpService.get(`${this.apiEndpointSP}/GetUserData/${encodeURIComponent(userId)}`);
		return response;
	}

	public async getUserDataForAgreementSubmission(userId: string): Promise<any> {
		const response = await HttpService.get(`${this.apiEndpointSP}/getUserDataForAgreementSubmission/${encodeURIComponent(userId)}`);
		return response;
	}

	public getBrands(): Promise<any> {
		try {
			return HttpService.get(`${this.apiEndpointSP}/Brands`);
		} catch (ex) {
			return Promise.reject((<any>ex).message);
		}
	}

	public getFilters(): Promise<any> {
		try {
			return HttpService.get(`${this.apiEndpointSP}/Filters`);
		} catch (ex) {
			return Promise.reject((<any>ex).message);
		}
	}
	public getProducts(brand: string): Promise<any> {
		try {
			return HttpService.post(`${this.apiEndpointSP}/Products`, brand);
		} catch (ex) {
			return Promise.reject((<any>ex).message);
		}
	}

	public getTypes(product: string): Promise<any> {
		try {
			return HttpService.post(`${this.apiEndpointSP}/Types`, product);
		} catch (ex) {
			return Promise.reject((<any>ex).message);
		}
	}

	public getSTLFilesWithoutAnyFilters(query: string): Promise<any> {
		try {
			return HttpService.post(`${this.apiEndpointSP}/STLFilesWithoutFilter`, query);
		} catch (ex) {
			return Promise.reject((<any>ex).message);
		}
	}

	public updateFilesDownloadedReportList(filesDownloaded: string[], userId: string): Promise<any> {
		const formData = {
			filesDownloaded: filesDownloaded.join(","),
			userId: userId,
		};
		try {
			return HttpService.post(`${this.apiEndpointSP}/UpdateDownloadReport`, formData);
		} catch (ex) {
			return Promise.reject((<any>ex).message);
		}
	}
	public getAlreadyDownloadedFiles(userId: string): Promise<any> {
		try {
			return HttpService.post(`${this.apiEndpointSP}/AlreadDownloadedFiles`, userId);
		} catch (ex) {
			return Promise.reject((<any>ex).message);
		}
	}
	public downloadFile(uniqueId: any): Promise<any> {
		try {
			return HttpService.get(`${this.apiEndpointSP}/DownloadFile/${encodeURIComponent(uniqueId)}`);
		} catch (ex) {
			return Promise.reject((<any>ex).message);
		}
	}

	public getSTLFilesWithFilters(camlQuery: string): Promise<any> {
		try {
			return HttpService.post(`${this.apiEndpointSP}/STLFilesWithFilter`, camlQuery);
		} catch (ex) {
			return Promise.reject((<any>ex).message);
		}
	}

	public logErrorInConsole(errorArea: string, errorObj: any) {
		this.logError(errorObj.message);
		console.log(`Error message ${errorArea}: `, errorObj.message);
		console.log(`Error Stack Trace ${errorArea}: `, errorObj.stack);
	}

	private logError(msg: any) {
		if (console) {
			console.log(`%cERROR:`, "color:red;font-weight:bold;font-size:16px");
			console.log(msg);
		}
	}

	public extractErrorMessageFromErrorObject(error: any): string {
		let errorMessage = error.message ? (error.message as string) : error.title ? (error.title as string) : error.toString();
		console.dir(error);
		if (errorMessage && errorMessage.indexOf("::>") >= 0) {
			try {
				let errorObj = JSON.parse(errorMessage.substring(errorMessage.indexOf("::>") + 3).trim());

				if (errorObj["odata.error"] && errorObj["odata.error"].message && errorObj["odata.error"].message.value)
					errorMessage = errorObj["odata.error"].message.value;
			} catch (error) {
				errorMessage = errorMessage.substring(errorMessage.indexOf("::>") + 3).trim();
			}
		}

		return errorMessage;
	}

	public addParam(param: string, sourceURL: string): string {
		var rtn = sourceURL.split("?")[0],
			params_arr = [],
			queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";

		if (queryString !== "") {
			params_arr = queryString.split("&");
			params_arr.push(param);
			if (params_arr.length > 0) rtn = rtn + "?" + params_arr.join("&");
		} else {
			rtn = rtn + "?" + param;
		}
		return rtn;
	}

	public ensureExternalUserAccount(user: IUserAccount, token: string, isRSMToken: boolean): Promise<IAuthenticatedUser> {
		return HttpService.post(`${this.userAccountEndpointSP}/EnsureExternalUserAccounts`, { Account: user, Token: token, IsRSMToken: isRSMToken });
	}
	public registerInternalUserAccount(user: IUserAccount, reCaptchaToken: string): Promise<boolean> {
		return HttpService.post(`${this.userAccountEndpointSP}/RegisterUserAccount`, {
			Account: user,
			BaseUrl: Common.getCurrentServerUrl(),
			reCaptchaToken: reCaptchaToken,
		});
	}
	public loginInternalUserAccount(user: IUserAccount, reCaptchaToken: string): Promise<IAuthenticatedUser> {
		return HttpService.post(`${this.userAccountEndpointSP}/CustomUserLogin`, { Account: user, reCaptchaToken: reCaptchaToken });
	}
	public getUserByActivateParam(activationGuid: string, a: boolean): Promise<boolean> {
		return HttpService.post(`${this.userAccountEndpointSP}/GetUserByActivateParam`, { activateParameter: activationGuid, activate: a });
	}
	public resetPWByActivateParam(activationGuid: string, pw: string): Promise<boolean> {
		return HttpService.post(`${this.userAccountEndpointSP}/ResetPWByActivateParam`, { activateParameter: activationGuid, password: pw });
	}
	public getNewActivationCode(email: string, baseUrl: string): Promise<string> {
		return HttpService.post(`${this.userAccountEndpointSP}/GetActivationCode`, { email: email, baseUrl: baseUrl });
	}
	public resendActivationCode(email: string, baseUrl: string): Promise<string> {
		return HttpService.post(`${this.userAccountEndpointSP}/ResendActivationCode`, { email: email, baseUrl: baseUrl });
	}
	public documentUpload(Id: string, base64File: string, applicantName: string): Promise<string> {
		return HttpService.post(`${this.apiEndpointSP}/UploadFileByBase64`, { id: Id, base64File: base64File, applicantName: applicantName });
	}

	public submitAgreement(accessRequest: IAccessRequest, base64File: string): Promise<string> {
		return HttpService.post(`${this.apiEndpointSP}/SubmitAgreement`, {
			id: accessRequest.Id,
			accessRequestId: accessRequest.AccessRequestId,
			base64File: base64File,
			applicantName: accessRequest.Name,
			userId: accessRequest.UserId,
			accessRequest: accessRequest,
		});
	}
}
