import { FontWeights, IButtonStyles, IIconProps, mergeStyleSets } from "office-ui-fabric-react";

export const cancelIcon: IIconProps = { iconName: "Cancel" };
export const iconButtonStyles: Partial<IButtonStyles> = {
	root: {
		marginLeft: "auto",
		marginTop: "4px",
		marginRight: "2px",
	},
};

export const contentStyles = mergeStyleSets({
	container: {
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "stretch",
	},
	header: {
		flex: "1 1 auto",
		borderTop: `4px solid`,
		display: "flex",
		alignItems: "center",
		fontWeight: FontWeights.semibold,
		padding: "12px 12px 14px 24px",
	},
	body: {
		width: "600px",
		padding: "0 24px 24px 24px",
		overflowY: "auto",
		selectors: {
			p: { margin: "14px 0" },
			"p:first-child": { marginTop: 0 },
			"p:last-child": { marginBottom: 0 },
		},
	},
});
