import {
	ColumnActionsMode,
	ConstrainMode,
	ContextualMenu,
	DetailsList,
	DetailsListLayoutMode,
	DirectionalHint,
	IColumn,
	Icon,
	IconButton,
	IContextualMenuItem,
	IDetailsColumnRenderTooltipProps,
	IDetailsHeaderProps,
	IRenderFunction,
	Link,
	mergeStyles,
	MessageBar,
	MessageBarType,
	Panel,
	PanelType,
	PrimaryButton,
	Selection,
	SelectionMode,
	Sticky,
	StickyPositionType,
	TextField,
	TooltipHost,
	Label,
} from "office-ui-fabric-react";
import * as React from "react";
import { detailsListStyle } from "./Constant";
import { DocumentsSearchAndDownloadService } from "../DocumentSearchAndDownloadService";
import { FilterPanel } from "./FilterPanel/FilterPanel";
import { cloneDeep } from "lodash";
import { VersionHistory } from "./VersionHistory/VersionHistory";
import moment from "moment";

interface IDocumentListViewProps {
	freeSearch: string;
	items: any[];
	allItems: any[];
	downloadedItems: any[];
	selectedBrand?: string;
	selectedProduct?: string;
	selectedType?: string;
	onDownloadClick: (selectedItems: any[]) => void;
	onSearchClick: () => void;
	onNewestDocumentClick: () => void;
	onFreeSearchChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
}

interface IDocumentListViewState {
	columns: any[];
	items: any[];
	currentItem: any;
	showVersionHistory: boolean;
	contextualMenuProps: any;
	currentSortedColumn: IColumn;
	panelProps: any;
	filterOptions: IFilterOption[];
	filteredItems: any[];
}

export interface IFilterOption {
	column: string;
	values: string[];
}

const DocumentListView = (props: IDocumentListViewProps) => {
	const [state, setState] = React.useState<IDocumentListViewState>({
		columns: [],
		contextualMenuProps: undefined,
		items: props.items ? props.items : [],
		currentSortedColumn: null,
		panelProps: {
			show: false,
			header: "",
			column: null,
		},
		filterOptions: null,
		filteredItems: [],
		showVersionHistory: false,
		currentItem: null,
	});
	const [selectedItems, setSelectedItems] = React.useState<any[] | undefined>([]);
	const selection = new Selection({
		onSelectionChanged: () => {
			setSelectedItems(selection.getSelection());
		},
	});

	const refColumns = React.useRef(state.columns);
	const refItems = React.useRef(state.filteredItems);
	React.useEffect(() => {
		(async () => {
			const viewColumnsSchema: IColumn[] = DocumentsSearchAndDownloadService.getColumnSchema(_onColumnContextMenu);
			refColumns.current = viewColumnsSchema;
			const currentSortedCol = viewColumnsSchema.filter((col) => col.isSorted == true)[0];
			setState((prevState) => ({ ...prevState, columns: viewColumnsSchema, currentSortedColumn: currentSortedCol }));
		})();
	}, []);

	React.useEffect(() => {
		refItems.current = props.items;
		const viewColumnsSchema: IColumn[] = DocumentsSearchAndDownloadService.getColumnSchema(_onColumnContextMenu);
		refColumns.current = viewColumnsSchema;
		const currentSortedColumn = viewColumnsSchema.filter((col) => col.isSorted == true)[0];
		const newColumns: IColumn[] = refColumns.current.slice();
		const currColumn: IColumn = newColumns.filter((currCol) => currentSortedColumn.key == currCol.key)[0];
		const newItems = DocumentsSearchAndDownloadService.onColumnClick(refColumns, currentSortedColumn, refItems);
		refItems.current = newItems;
		setState((prevState) => ({ ...prevState, items: props.items, columns: newColumns, filteredItems: newItems, currentSortedColumn: currColumn }));
		//setState((prevState) => ({ ...prevState, items: props.items, filteredItems: props.items }));
	}, [props.items]);

	const _onColumnContextMenu = (column?: IColumn, ev?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (column.columnActionsMode !== ColumnActionsMode.disabled) {
			const props = getContextualMenuProps(column, ev);
			setState((prevState) => ({ ...prevState, contextualMenuProps: props }));
		}
	};

	const getContextualMenuProps = (column?: IColumn, ev?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		let items: IContextualMenuItem[] = DocumentsSearchAndDownloadService.getContextualSchema(column, _onColumnClick, _onFilterClick) || [];
		return {
			items: items,
			target: ev.currentTarget as HTMLElement,
			directionalHint: DirectionalHint.bottomLeftEdge,
			gapSpace: 5,
			isBeakVisible: true,
			onDismiss: _onContextualMenuDismissed,
		};
	};

	const _onContextualMenuDismissed = () => {
		setState((prevState) => ({ ...prevState, contextualMenuProps: undefined }));
	};

	const _onColumnClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn): void => {
		const newColumns: IColumn[] = refColumns.current.slice();
		const currColumn: IColumn = newColumns.filter((currCol) => column.key == currCol.key)[0];
		const newItems = DocumentsSearchAndDownloadService.onColumnClick(refColumns, column, refItems);
		refItems.current = newItems;
		setState((prevState) => ({ ...prevState, columns: newColumns, filteredItems: newItems, currentSortedColumn: currColumn }));
	};

	const _onFilterClick = (column: IColumn, ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
		//setIsPanelVisible({ show: true, header: `Filter by '${column.name}'`, column: column });
		setState((prevState: IDocumentListViewState) => ({ ...prevState, panelProps: { show: true, header: `Filter by '${column.name}'`, column: column } }));
	};

	const dismissPanel = () => {
		setState((prevState: IDocumentListViewState) => ({ ...prevState, panelProps: { show: false, header: "", column: null } }));
	};

	const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
		if (!props) {
			return null;
		}
		const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />;
		return (
			<div>
				{defaultRender!({
					...props,
					onRenderColumnHeaderTooltip,
				})}
			</div>
		);
	};

	const onRenderItemColumn = (item: any, index: number, column: IColumn) => {
		if (column.key == "Downloaded") {
			const hasOlderVersions = item["HasOlderVersion"] as boolean;
			if (hasOlderVersions) {
				return (
					<>
						<span>{item["Downloaded"]}</span>
						<Icon iconName="Info" className={mergeStyles({ color: "black !important", paddingLeft: 15 })} onClick={() => onHistoryButtonClick(item)} />
					</>
				);
			} else {
				return <span>{item["Downloaded"]}</span>;
			}
		} else if (column.key == "Material") {
			return <span>{item[column.fieldName] ? (item[column.fieldName] as number) : null}</span>;
		} else {
			return <span>{item[column.fieldName]}</span>;
		}
	};

	const onHistoryButtonClick = (item: any) => {
		setState((prevState) => ({ ...prevState, showVersionHistory: true, currentItem: item }));
	};

	const handleDismissModal = () => {
		setState((prevState) => ({ ...prevState, showVersionHistory: false }));
	};

	const handleFilterSelection = async (filterValues: string[]) => {
		dismissPanel();
		let objFilterOption: IFilterOption[] = [];
		const newColumns = cloneDeep(state.columns);
		if (filterValues.length == 0) {
			if (state.filterOptions) {
				objFilterOption = [...state.filterOptions];
				const index = objFilterOption.findIndex((obj) => obj.column == state.panelProps.column.fieldName);
				if (index != -1) {
					objFilterOption.splice(index, 1);
				}
			}
			const column = newColumns.filter((column) => column?.fieldName == state.panelProps.column.fieldName);
			column[0].isFiltered = false;
			setState((prevState) => ({ ...prevState, filterOptions: objFilterOption }));
			if (objFilterOption.length == 0) {
				newColumns.forEach((column) => (column.isFiltered = false));
				refItems.current = state.items;
				setState((prevState) => ({ ...prevState, filteredItems: state.items }));
			} else {
				const response = DocumentsSearchAndDownloadService.filterDataByColumn(state.items, objFilterOption);
				const newItems = DocumentsSearchAndDownloadService.copyAndSort(
					response,
					state.currentSortedColumn.fieldName!,
					state.currentSortedColumn.isSortedDescending
				);
				refItems.current = newItems;
				setState((prevState) => ({ ...prevState, filteredItems: newItems }));
			}
		} else {
			if (state.filterOptions) {
				objFilterOption = [...state.filterOptions];
			}
			const index = objFilterOption.findIndex((obj) => obj.column == state.panelProps.column.fieldName);
			if (index != -1) {
				objFilterOption[index].values = [];
				filterValues.forEach((value) => {
					objFilterOption[index].values.push(value);
				});
			} else {
				let values: any = [];
				filterValues.forEach((value) => {
					values.push(value);
				});
				objFilterOption.push({
					column: state.panelProps.column.fieldName,
					values: values,
				});
			}

			const column = newColumns.filter((column) => column.fieldName == state.panelProps.column.fieldName);
			column[0].isFiltered = true;

			const response = DocumentsSearchAndDownloadService.filterDataByColumn(state.items, objFilterOption);
			const newItems = DocumentsSearchAndDownloadService.copyAndSort(
				response,
				state.currentSortedColumn.fieldName!,
				state.currentSortedColumn.isSortedDescending
			);

			refItems.current = newItems;
			setState((prevState) => ({ ...prevState, filteredItems: newItems, filterOptions: objFilterOption }));
		}
		refColumns.current = newColumns;
		setState((prevState) => ({ ...prevState, columns: newColumns }));
		console.log(objFilterOption);
	};

	const onFreeSearchChange = (ev: any, value: string) => {
		let filteredItemsClone = JSON.parse(JSON.stringify(refItems.current));
		if (value) {
			filteredItemsClone = DocumentsSearchAndDownloadService.getFilteredItemsForSearchTerm(value, filteredItemsClone, state.items);
		}

		if (state.currentSortedColumn) {
			filteredItemsClone = DocumentsSearchAndDownloadService.copyAndSort(
				filteredItemsClone,
				state.currentSortedColumn.fieldName!,
				state.currentSortedColumn.isSortedDescending
			);
		}

		setState((prevState) => ({ ...prevState, filteredItems: filteredItemsClone }));
	};

	return (
		<>
			<div className="row"></div>
			<div className="row">
				<div className="column2">
					<Link onClick={props.onNewestDocumentClick}>Newest Documents</Link>
					<IconButton
						styles={{ root: { marginLeft: "auto", marginTop: 4, marginRight: 2 } }}
						iconProps={{ iconName: "Refresh" }}
						ariaLabel="Refresh"
						onClick={props.onNewestDocumentClick}
					/>
				</div>
				<div className="column3"></div>
				<div className="column4"></div>
				<div className="column3" style={{ textAlign: "right" }}>
					<PrimaryButton text="Search" onClick={props.onSearchClick}></PrimaryButton>
				</div>
			</div>
			<div className="row">
				<div className="column6"></div>

				<div className="column4"></div>
				<div className="column2" style={{ textAlign: "right" }}>
					<TextField onChange={onFreeSearchChange} />
				</div>
			</div>
			<div className="row" style={{ marginTop: 0, paddingTop: 0 }}>
				<div className="column12" style={{ maxHeight: "30vh", width: "100%", overflow: "auto" }} data-is-scrollable="true">
					<DetailsList
						columns={state.columns}
						selection={selection}
						styles={detailsListStyle}
						items={state.filteredItems}
						layoutMode={DetailsListLayoutMode.fixedColumns}
						selectionMode={SelectionMode.multiple}
						constrainMode={ConstrainMode.unconstrained}
						isHeaderVisible={true}
						selectionPreservedOnEmptyClick={false}
						onRenderDetailsHeader={onRenderDetailsHeader}
						onRenderItemColumn={onRenderItemColumn}
					></DetailsList>
				</div>
			</div>
			<div className="row">
				<div className="column2">
					<Label>Total Materials {state.filteredItems?.length}</Label>
				</div>
			</div>
			<Panel
				isOpen={state.panelProps.show}
				onDismiss={dismissPanel}
				type={PanelType.smallFixedFar}
				closeButtonAriaLabel="Close"
				headerText={state.panelProps.header}
			>
				<FilterPanel
					data={state.items}
					selectedFilterOptions={state.filterOptions}
					filterBy={state.panelProps.column}
					onCancel={dismissPanel}
					onApply={handleFilterSelection}
				></FilterPanel>
			</Panel>
			<VersionHistory
				isModalOpen={state.showVersionHistory}
				onDismissModal={handleDismissModal}
				allItems={props.allItems}
				downloadedItems={props.downloadedItems}
				currentItem={state.currentItem}
			></VersionHistory>
			{state.contextualMenuProps && <ContextualMenu {...state.contextualMenuProps}></ContextualMenu>}
			<div className="row">
				<div className="column2"></div>
				<div className="column3"></div>
				<div className="column4"></div>
				<div className="column3" style={{ textAlign: "right" }}>
					<PrimaryButton text="Download" onClick={() => props.onDownloadClick(selectedItems)}></PrimaryButton>
				</div>
			</div>
			<div className="row">
				<MessageBar messageBarType={MessageBarType.info}>
					If you miss a document on our database contact us on <a href="mailto:STL_Library_Request@straumann.com"> STL_Library_Request@straumann.com</a>
				</MessageBar>
			</div>
		</>
	);
};

export default DocumentListView;
