import * as React from "react";
import { css, Stack, Dialog, Spinner, DialogType, SpinnerSize } from "office-ui-fabric-react";
import { IUserAccount } from "../LoginComponent/LoginComponent";
import { IAccessRequest } from "../../Models/IAccessRequest";
import "./../../Styles/Access.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import { AccessRequestSchema } from "../../Models/Constant";
import { IDataProvider } from "../../dataprovider/IDataProvider";
import { IAskAccess } from "../../Models/IAskAccess";
import { RequesterData } from "../RequesterData/RequesterData";
import { CaptchaAndSubmitAction } from "../RequestCaptchaAndSubmitAction/RequestCaptchaAndSubmitAction";
import { AttachmentComponent } from "../AttachmentComponent/AttachmentComponent";
import { IFileItem } from "../../Models/IFileItem";
import { PageURL } from "../../App";
export interface ISubmitAgreementProps {
	location: Location;
	history: any;
	stepSizeClass: string;
	isLoggedIn: boolean;
	dataProvider: IDataProvider;
	currentUser?: IUserAccount;
}

export interface ISubmitAgreementState {
	data: IAskAccess;
	reCaptchaToken: string;
	isCaptchaValid: boolean;
	isProcessing: boolean;
	attachment: any;
	requestId: string;
	noAttachmentError: string;
	canSubmitAgreement: boolean;
	showInReviewMsg: boolean;
	showSuccessMessage: boolean;
	showSpinner: boolean;
	showInitialLoader: boolean;
	showNoRequestFound: boolean;
}

const extensionFilters = [".pdf"];
export const SubmitAgreement = (props: ISubmitAgreementProps) => {
	const refReCaptchaRegister = React.createRef<ReCAPTCHA>();
	const { id } = useParams<{ id: string }>();
	const [state, setState] = React.useState<ISubmitAgreementState>({
		data: AccessRequestSchema,
		reCaptchaToken: "",
		isCaptchaValid: true,
		isProcessing: false,
		attachment: null,
		requestId: null,
		noAttachmentError: null,
		canSubmitAgreement: false,
		showInReviewMsg: false,
		showSuccessMessage: false,
		showSpinner: false,
		showInitialLoader: false,
		showNoRequestFound: false,
	});

	React.useEffect(() => {
		(async () => {
			const dataClone: IAskAccess = cloneDeep(state.data);
			try {
				setState((prevState) => ({ ...prevState, showInitialLoader: true }));
				let requestId: string = null;
				let canCreateNewRequest = false;
				let currentRequestStatus = "";
				let canSubmitAgreement = false;
				let showInReviewMsg = false;
				let noRequestFound = false;
				const response: any = await props.dataProvider.getUserDataForAgreementSubmission(id);

				if (response) {
					canCreateNewRequest = response["canCreateNewRequest"] ? response["canCreateNewRequest"] : false;
					currentRequestStatus = response["currentRequestStatus"] ? response["currentRequestStatus"] : "";
					if (currentRequestStatus == "AccessApproved") {
						props.history.push(PageURL.Download);
					}
					if (!canCreateNewRequest && currentRequestStatus == "DocumentSubmitted") {
						showInReviewMsg = true;
					}
					if (currentRequestStatus == "AskForAccess") {
						if (id) {
							const response = await props.dataProvider.getAccessRequestForAgreementSubmission(id);
							dataClone.Company.value = response["company"];
							dataClone.Name.value = response["name"];
							dataClone.Reason.value = response["reason"];
							dataClone.EMail.value = response["eMail"];
							dataClone.AccessRequestId.value = response["title"];
							dataClone.Id.value = response["id"];
							dataClone.IsAgreed.value = "Yes";
							requestId = response["title"];
							canSubmitAgreement = true;
							noRequestFound = false;
						}
					}
					if (currentRequestStatus == "" || currentRequestStatus == "AccessDenied") {
						props.history.push("/");
					}
				}
				if (!response) {
					noRequestFound = true;
				}

				setState((prevState) => ({
					...prevState,
					showInitialLoader: false,
					requestId: requestId,
					data: dataClone,
					canSubmitAgreement: canSubmitAgreement,
					showInReviewMsg: showInReviewMsg,
					showNoRequestFound: noRequestFound,
				}));
			} catch (ex) {
				console.log(ex);
				setState((prevState) => ({
					...prevState,
					showInitialLoader: false,
					canSubmitAgreement: false,
					showInReviewMsg: false,
					showNoRequestFound: true,
				}));
			}
		})();
	}, []);

	const handleTextFieldChange = (fieldName: string, value: string) => {
		const dataClone: any = cloneDeep(state.data);
		dataClone[fieldName].value = value;

		setState((prevState) => ({ ...prevState, data: dataClone }));
	};

	const handleCheckBoxChange = (fieldName: string, value: boolean) => {
		const dataClone: any = cloneDeep(state.data);
		if (value) {
			dataClone[fieldName].value = "Yes";
		} else {
			dataClone[fieldName].value = "";
		}
		setState((prevState) => ({ ...prevState, data: dataClone }));
	};

	const validateFormFields = () => {
		setState((prevState) => ({ ...prevState, isProcessing: true }));
		let formValid = true;
		let captchaValid = true;
		const dataClone: any = cloneDeep(state.data);
		Object.keys(dataClone).forEach((field) => {
			if (dataClone[field].hasOwnProperty("value") && dataClone[field].value != null && dataClone[field].value != "") {
				dataClone[field].valid = true;
			} else {
				dataClone[field].valid = false;
				formValid = false;
			}
		});
		if (state.reCaptchaToken == "") {
			captchaValid = false;
			formValid = false;
		}

		if (!state.attachment) {
			formValid = false;
			setState((prevState) => ({ ...prevState, noAttachmentError: "Please attach signed agreement." }));
		}
		setState((prevState) => ({ ...prevState, data: dataClone, isProcessing: false, isCaptchaValid: captchaValid }));
		return formValid;
	};

	const onSubmitButtonClicked = async () => {
		setState((prevState) => ({ ...prevState, showSpinner: true }));

		const isFormValid = validateFormFields();

		if (isFormValid) {
			if (state.attachment) {
				let file: any = await toBase64(state.attachment);
				file = file.split(",")[1];
				const accessRequestData: IAccessRequest = {};
				const dataClone: any = cloneDeep(state.data);
				Object.keys(dataClone).forEach((field) => {
					(accessRequestData as any)[field] = (dataClone as any)[field].value;
				});
				accessRequestData.UserId = id;

				props.dataProvider
					.submitAgreement(accessRequestData, file)
					.then(async (result: any) => {
						setState((prevState) => ({ ...prevState, showSpinner: false, showSuccessMessage: true, canSubmitAgreement: false }));
					})
					.catch((error) => {
						console.log(error);
						setState((prevState) => ({ ...prevState, showSpinner: false, showSuccessMessage: false, canSubmitAgreement: true }));
					});
			}
		} else {
			setState((prevState) => ({ ...prevState, showSpinner: false }));
		}
	};

	const handleFilesAdded = (files: File[]): Promise<void> => {
		setState((prevState) => ({ ...prevState, attachment: files[0] }));
		return Promise.resolve();
	};

	const handleFilesDeleted = (file: File | IFileItem): Promise<void> => {
		setState((prevState) => ({ ...prevState, attachment: null }));
		return Promise.resolve();
	};

	const toBase64 = (file: File) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	return (
		<>
			{state.showInitialLoader && <Spinner size={SpinnerSize.large} ariaLive="assertive" />}
			{state.canSubmitAgreement && (
				<Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
					<Dialog
						hidden={!state.showSpinner}
						dialogContentProps={{
							type: DialogType.normal,
						}}
						modalProps={{ isBlocking: true, className: "busyOverlay" }}
					>
						<Spinner size={SpinnerSize.large} label="Working on it..." ariaLive="assertive" />
					</Dialog>
					<RequesterData data={state.data} onCheckBoxChange={handleCheckBoxChange} onTextFieldChange={handleTextFieldChange} readonly={true}></RequesterData>
					<fieldset>
						<legend>Upload Agreement(as pdf)</legend>
						<AttachmentComponent
							apiEndpointSP={`/api/SPInterface`}
							attachment={state.attachment}
							readonly={false}
							deletePermissions="Own"
							enableDropZone={true}
							attachmentClickBehaviour="Download"
							extensionFilters={extensionFilters}
							fileMode="SingleFile"
							onFilesAdded={handleFilesAdded}
							onFileDeleted={handleFilesDeleted}
							maxFileNameWidth={"700px"}
							errorMessage={!state.attachment ? state.noAttachmentError : null}
						></AttachmentComponent>
					</fieldset>
					<CaptchaAndSubmitAction
						onRecaptchaToken={(token) => setState((prevState) => ({ ...prevState, reCaptchaToken: token }))}
						onButtonClicked={onSubmitButtonClicked}
						refReCaptchaRegister={refReCaptchaRegister}
						isCaptchaValid={state.isCaptchaValid}
						isSubmitProcessing={state.isProcessing}
						buttonTitle="Submit"
					/>
				</Stack>
			)}
			{state.showSuccessMessage && (
				<Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
					<div style={{ paddingTop: "20%", textAlign: "center" }}>Your agreement has been submitted</div>
				</Stack>
			)}
			{state.showInReviewMsg && (
				<Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
					<div style={{ paddingTop: "20%", textAlign: "center" }}>Your current request is in review state</div>
				</Stack>
			)}
			{state.showNoRequestFound && (
				<Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
					<div style={{ paddingTop: "20%", textAlign: "center" }}>No request found for your Id</div>
				</Stack>
			)}
		</>
	);
};
