import { ColumnActionsMode, IColumn, IContextualMenuItem } from "office-ui-fabric-react";
import { cellStyle } from "./DocumentsListView/Constant";
import moment from "moment";
import CamlBuilder from "camljs";
import { IFilterOption } from "./DocumentsListView/DocumentsListView";
import { cloneDeep } from "lodash";

export namespace DocumentsSearchAndDownloadService {
  export const getColumnSchema = (_onColumnContextMenu: any): IColumn[] => {
    const viewColumnsSchema: IColumn[] = [
      // {
      // 	key: "Names",
      // 	name: "Names",
      // 	fieldName: "Name",
      // 	minWidth: 250,
      // 	maxWidth: 250,
      // 	styles: cellStyle,
      // 	columnActionsMode: ColumnActionsMode.hasDropdown,
      // 	onColumnClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => _onColumnContextMenu(column, ev),
      // 	onColumnContextMenu: _onColumnContextMenu,
      // },
      {
        key: "Material",
        name: "Material Number",
        fieldName: "Material",
        minWidth: 100,
        maxWidth: 150,
        styles: cellStyle,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onColumnClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => _onColumnContextMenu(column, ev),
        onColumnContextMenu: _onColumnContextMenu,
      },
      {
        key: "FileDescription",
        name: "Description",
        fieldName: "FileDescription",
        minWidth: 250,
        maxWidth: 250,
        styles: cellStyle,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onColumnClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => _onColumnContextMenu(column, ev),
        onColumnContextMenu: _onColumnContextMenu,
      },
      {
        key: "Brands",
        name: "Brands",
        fieldName: "Brand",
        minWidth: 100,
        maxWidth: 100,
        styles: cellStyle,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onColumnClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => _onColumnContextMenu(column, ev),
        onColumnContextMenu: _onColumnContextMenu,
      },

      {
        key: "Products",
        name: "Implantlines",
        fieldName: "Product",
        minWidth: 150,
        maxWidth: 150,
        styles: cellStyle,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onColumnClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => _onColumnContextMenu(column, ev),
        onColumnContextMenu: _onColumnContextMenu,
      },
      {
        key: "Types",
        name: "Types",
        fieldName: "Type",
        minWidth: 100,
        maxWidth: 100,
        styles: cellStyle,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onColumnClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => _onColumnContextMenu(column, ev),
        onColumnContextMenu: _onColumnContextMenu,
      },

      {
        key: "LastUpdatedDate",
        name: "Last Updated Date",
        fieldName: "LastUpdatedDate",
        minWidth: 150,
        maxWidth: 150,
        styles: cellStyle,
        isSortedDescending: false,
        isSorted: true,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onColumnClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => _onColumnContextMenu(column, ev),
        onColumnContextMenu: _onColumnContextMenu,
      },
      {
        key: "Downloaded",
        name: "Downloaded",
        fieldName: "Downloaded",
        minWidth: 100,
        maxWidth: 100,
        styles: cellStyle,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onColumnClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => _onColumnContextMenu(column, ev),
        onColumnContextMenu: _onColumnContextMenu,
      },
    ];

    return viewColumnsSchema;
  };

  export const getContextualSchema = (column: IColumn, _onColumnClick: any, _onFilterClick: any): IContextualMenuItem[] => {
    let items: IContextualMenuItem[] = [];
    if (column.fieldName != "LastUpdatedDate") {
      items = [
        {
          key: "aToZ",
          name: "Sort",
          iconProps: { iconName: "Sort" },
          onClick: (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) =>
            _onColumnClick(ev, column),
        },
      ];
    } else {
      items = [
        {
          key: "oldToNew",
          name: "Sort",
          iconProps: { iconName: "Sort" },
          onClick: (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) =>
            _onColumnClick(ev, column),
        },
      ];
    }
    if (column.fieldName == "LastUpdatedDate" || column.fieldName == "Downloaded") {
      items.push({
        key: "filterBy",
        name: "Filter by ", // + column.name,
        canCheck: true,
        checked: column.isFiltered,
        onClick: (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) =>
          _onFilterClick(column, ev, item),
      });
    }
    return items;
  };

  export const filterDataByColumn = (data: any, filterValues: IFilterOption[]) => {
    let results: any[] = [];
    let dataClone = cloneDeep(data);
    filterValues.forEach((filter) => {
      //dataClone = dataClone.filter(filterByColumn(filter.column, value));
      dataClone = dataClone.filter((item: any) => filter.values.includes(item[filter.column]));
    });

    return dataClone;
  };
  const escapeRegExp = (str: any) => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  const filterBy = (term: any) => {
    const re = new RegExp(escapeRegExp(term), "i");
    return (item: any) => {
      for (let prop in item) {
        if (!item.hasOwnProperty(prop)) {
          continue;
        }
        if (re.test(item[prop])) {
          return true;
        }
      }
      return false;
    };
  };
  export const getFilteredItemsForSearchTerm = (searchTerm: string, filteredItems: any[], initialItems: any[]) => {
    if (searchTerm === "") {
      return initialItems;
    } else {
      const filterItemsClone = JSON.parse(JSON.stringify(filteredItems));

      searchTerm = searchTerm.toLowerCase();
      let filterItemsArr: any[] = [];
      const found: any[] = filterItemsClone.filter(filterBy(searchTerm));
      if (found) {
        found.forEach((element) => {
          filterItemsArr.push(element);
        });
      }
      return filterItemsArr;
    }
  };
  export const onColumnClick = (refColumns: any, column: IColumn, refItems: any) => {
    const newColumns: IColumn[] = refColumns.current.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key == currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol == currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    const newItems = copyAndSort(refItems.current, currColumn.fieldName!, currColumn.isSortedDescending);
    return newItems;
  };
  export const getValuesForFilterByOption = (data: any, filterBy: IColumn) => {
    let results = data.map((d: any) => (d[filterBy.fieldName] != undefined ? d[filterBy.fieldName] : ""));
    //let uniqueArray = [...new Set(results)];
    let uniqueArray = Array.from(new Set(results));
    return uniqueArray;
  };
  export const copyAndSort = <T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] => {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  };

  export const generateListViewItems = (
    data: any,
    alreadyDownloadedFiles: any,
    isVip: boolean,
    approvedFileTypes: string[],
    approvedProducts: string[]
  ): any => {
    let viewItems: any[] = [];
    let downloadedFiles: any[] = JSON.parse(alreadyDownloadedFiles);
    let sortedData: any[] = [];
    let resultNew = [];
    if (data) {
      const parsedData: any[] = JSON.parse(data);

      if (parsedData.length > 0) {
        sortedData = parsedData.sort((a, b) => a.Material.localeCompare(b.Material) || b.FileVersion - a.FileVersion);
        const result: any = {};
        for (const el of sortedData) {
          if (!result[el["Material"]]) result[el["Material"]] = [];
          result[el["Material"]].push(el);
        }

        for (const group of Object.values(result)) {
          const item: any = group;
          resultNew.push(item.find((it: any) => it.FileVersion) || item[0]);
        }
      }

      resultNew.forEach((data, i) => {
        let isVaildEntry = false;
        if (!isVip) {
          if (approvedFileTypes.length > 0 && approvedProducts.length > 0) {
            if (approvedFileTypes.some((ft) => ft === data["FileType"]) && approvedProducts.some((pr) => pr === data["Product"])) {
              isVaildEntry = true;
            }
          } else if (approvedFileTypes.length > 0 && approvedProducts.length === 0) {
            if (approvedFileTypes.some((ft) => ft === data["FileType"])) {
              isVaildEntry = true;
            }
          } else if (approvedFileTypes.length === 0 && approvedProducts.length > 0) {
            if (approvedProducts.some((pr) => pr === data["Product"])) {
              isVaildEntry = true;
            }
          } else if (approvedFileTypes.length === 0 && approvedProducts.length === 0) {
            isVaildEntry = false;
          }
        } else {
          isVaildEntry = true;
        }
        if (isVaildEntry) {
          viewItems.push({
            Brand: data["Brand"],
            Product: data["Product"],
            Type: data["FileType"],
            LastUpdatedDate: data["Modified"] ? moment(data["LaunchDate"]).format("DD.MM.YYYY") : null,
            Downloaded:
              downloadedFiles.filter((dFile) => dFile["FileUniqueId"] == data["UniqueId"] && dFile["FileVersion"] == data["FileVersion"]).length > 0
                ? "Yes"
                : "No",
            ServerRelativeUrl: data["ServerRelativeUrl"],
            LaunchDate: data["LaunchDate"] ? moment(data["LaunchDate"]).format("DD.MM.YYYY") : "",
            Material: data["Material"],
            IsVIP: data["IsVIP"],
            DocType: data["DocType"],
            Title: data["Name"],
            Name: data["Name"],
            UniqueId: data["UniqueId"],
            FileVersion: data["FileVersion"],
            FileDescription: data["FileDescription"],
            HasOlderVersion:
              sortedData.filter((val) => val["Material"] == data["Material"] && new Date(data["LaunchDate"]) <= new Date()).length > 1 ? true : false,
          });
        }
      });
    }
    const returnValue: any = {
      ViewItems: viewItems,
      AllItems: sortedData,
    };
    return returnValue;
  };

  export const getCamlQueryForSearchFilters = (brand: string, product: string, type: string, material: string, IsVIP: boolean) => {
    let camlBuilder = new CamlBuilder().Query().Where().NumberField("ID").GreaterThan(0).ToString();

    //let camlBuilder = new CamlBuilder().Query().Where().NumberField("ID").GreaterThan(0).And().DateField("LaunchDate").LessThanOrEqualTo(new Date()).ToString();
    let query: any;

    if (!IsVIP) {
      query = CamlBuilder.FromXml(camlBuilder).ModifyWhere().AppendAnd().DateField("LaunchDate").LessThanOrEqualTo(new Date()).ToString();
      if (query) {
        query = CamlBuilder.FromXml(query.toString()).ModifyWhere().AppendAnd().BooleanField("IsVIP").IsFalse().ToString();
      } else {
        query = CamlBuilder.FromXml(camlBuilder).ModifyWhere().AppendAnd().BooleanField("IsVIP").IsFalse().ToString();
      }
    }
    if (brand != null && brand != undefined && brand != "") {
      if (query) {
        query = CamlBuilder.FromXml(query.toString()).ModifyWhere().AppendAnd().LookupField("Brand").ValueAsText().EqualTo(brand).ToString();
      } else {
        query = CamlBuilder.FromXml(camlBuilder).ModifyWhere().AppendAnd().LookupField("Brand").ValueAsText().EqualTo(brand).ToString();
      }
    }
    if (product != null && product != undefined && product != "") {
      if (query) {
        query = CamlBuilder.FromXml(query.toString()).ModifyWhere().AppendAnd().LookupField("Product").ValueAsText().EqualTo(product).ToString();
      } else {
        query = CamlBuilder.FromXml(camlBuilder).ModifyWhere().AppendAnd().LookupField("Product").ValueAsText().EqualTo(product).ToString();
      }
    }
    if (type != null && type != undefined && type != "") {
      if (query) {
        query = CamlBuilder.FromXml(query.toString()).ModifyWhere().AppendAnd().LookupField("FileType").ValueAsText().EqualTo(type).ToString();
      } else {
        query = CamlBuilder.FromXml(camlBuilder).ModifyWhere().AppendAnd().LookupField("FileType").ValueAsText().EqualTo(type).ToString();
      }
    }
    if (material != null && material != undefined && material != "") {
      if (query) {
        query = CamlBuilder.FromXml(query.toString()).ModifyWhere().AppendAnd().TextField("Material").Contains(material).ToString();
      } else {
        query = CamlBuilder.FromXml(camlBuilder).ModifyWhere().AppendAnd().TextField("Material").Contains(material).ToString();
      }
    }
    if (query != undefined && query != null) {
      return query;
    } else {
      return camlBuilder;
    }
  };
}
