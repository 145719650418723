import { Stack, css, IDropdownOption, Spinner, SpinnerSize, Dialog, DialogType } from "office-ui-fabric-react";
import * as React from "react";
import { IDataProvider } from "../../dataprovider/IDataProvider";
import { IUserAccount } from "../LoginComponent/LoginComponent";
import { DocumentsSearchAndDownloadService } from "./DocumentSearchAndDownloadService";
import DocumentSearchFilters from "./DocumentSearchFilters/DocumentSearchFilters";
import DocumentListView from "./DocumentsListView/DocumentsListView";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { cloneDeep } from "lodash";
export interface IDocumentSearchAndDownloadProps {
  location: Location;
  history: any;
  stepSizeClass: string;
  isLoggedIn: boolean;
  dataProvider: IDataProvider;
  currentUser?: IUserAccount;
}

export interface IDocumentSearchAndDownloadState {
  brands: IDropdownOption[];
  products: any[];
  productValues: IDropdownOption[];
  types: IDropdownOption[];
  materials: IDropdownOption[];
  isProductsDisabled: boolean;
  isTypeDisabled: boolean;
  selectedBrand: string;
  selectedProduct: string;
  selectedType: string;
  selectedMaterial: string;
  showSpinner: boolean;
  brandsLoaded: boolean;
  viewItems: any[];
  allViewItems: any[];
  allItems: any[];
  downloadedItems: any[];
  isVIP: boolean;
  alreadyDownloadedFiles: any;
  hasAccess: any;
  showAccessDeniedMessage: boolean;
  showRequestInProgressMessage: boolean;
  initialLoader: boolean;
  showAccessRequestRejectedMessage: boolean;
  freeSearch: string;
  approvedFileTypes: string[];
  approvedProducts: string[];
}

export const DocumentSearchAndDownload = (props: IDocumentSearchAndDownloadProps) => {
  const [state, setState] = React.useState<IDocumentSearchAndDownloadState>({
    brands: [],
    products: [],
    productValues: [],
    types: [],
    materials: [],
    isProductsDisabled: true,
    isTypeDisabled: true,
    selectedBrand: null,
    selectedProduct: null,
    selectedMaterial: null,
    selectedType: null,
    showSpinner: false,
    brandsLoaded: false,
    isVIP: false,
    viewItems: [],
    allViewItems: [],
    alreadyDownloadedFiles: null,
    downloadedItems: [],
    allItems: [],
    hasAccess: false,
    showAccessDeniedMessage: false,
    showRequestInProgressMessage: false,
    initialLoader: false,
    showAccessRequestRejectedMessage: false,
    freeSearch: null,
    approvedProducts: [],
    approvedFileTypes: [],
  });

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      initialLoader: true,
    }));
    (async () => {
      const brandOptions: IDropdownOption[] = [];
      const productOptions: IDropdownOption[] = [];
      let products: any[] = [];
      const typeOptions: IDropdownOption[] = [];
      const materialOptions: IDropdownOption[] = [];
      let alreadyDownloadedFiles: any = null;
      let isUserVIP: boolean = false;
      let items: any = {};
      let hasAccess = false;
      let showAccessDeniedMessage = false;
      let showRequestInProgressMessage = false;
      let approvedFileTypes: string[] = [];
      let approvedProducts: string[] = [];
      try {
        //const requestStatusResponse = await props.dataProvider.getAccessRequest(props.currentUser.userId);
        const userDetailsResponse = await props.dataProvider.getUserData(props.currentUser.userId);
        if (userDetailsResponse) {
          var requestStatus = userDetailsResponse["currentRequestStatus"];
          if (requestStatus == "AccessApproved") {
            hasAccess = true;
            showAccessDeniedMessage = false;
            showRequestInProgressMessage = false;
            //const isVIP = userDetailsResponse["isVIP"] ? "true" : "false";
            isUserVIP = userDetailsResponse["isVIP"];

            const camlQuery = DocumentsSearchAndDownloadService.getCamlQueryForSearchFilters(null, null, null, null, isUserVIP);
            const [documentsResponse, downloadedFiles, responseFilters] = await Promise.all([
              props.dataProvider.getSTLFilesWithoutAnyFilters(camlQuery),
              props.dataProvider.getAlreadyDownloadedFiles(props.currentUser.userId),
              props.dataProvider.getFilters(),
            ]);

            alreadyDownloadedFiles = downloadedFiles;
            approvedFileTypes = userDetailsResponse.approvedFileTypes;
            approvedProducts = userDetailsResponse.approvedProducts;
            items = DocumentsSearchAndDownloadService.generateListViewItems(
              documentsResponse,
              alreadyDownloadedFiles,
              isUserVIP,
              approvedFileTypes,
              approvedProducts
            );
            // const responseFilters: any = await props.dataProvider.getFilters();
            const parsedResponse = JSON.parse(responseFilters);
            const brands: string[] = parsedResponse["Brands"];
            products = parsedResponse["Products"];
            const types: string[] = parsedResponse["Types"];

            if (parsedResponse != null) {
              brandOptions.push({ key: null, text: null });
              productOptions.push({ key: null, text: null });
              typeOptions.push({ key: null, text: null });
              brands.forEach((value: string) => {
                brandOptions.push({ key: value, text: value });
              });

              products.forEach((value: any) => {
                productOptions.push({ key: value["Title"], text: value["Title"] });
              });

              types.forEach((value: string) => {
                typeOptions.push({ key: value, text: value });
              });
            }
          } else if (requestStatus == "AccessDenied") {
            hasAccess = false;
            showAccessDeniedMessage = true;
            showRequestInProgressMessage = false;
          } else {
            hasAccess = false;
            showRequestInProgressMessage = true;
            showAccessDeniedMessage = false;
          }
        } else {
          hasAccess = false;
          showRequestInProgressMessage = false;
          showAccessDeniedMessage = true;
        }

        setState((prevState) => ({
          ...prevState,
          brands: brandOptions,
          brandsLoaded: true,
          showSpinner: false,
          initialLoader: false,
          viewItems: items["ViewItems"],
          allViewItems: items["ViewItems"],
          products: products,
          productValues: productOptions,
          types: typeOptions,

          isVIP: isUserVIP,
          approvedFileTypes: approvedFileTypes,
          approvedProducts: approvedProducts,
          alreadyDownloadedFiles: alreadyDownloadedFiles,
          allItems: items["AllItems"],
          downloadedItems: alreadyDownloadedFiles ? JSON.parse(alreadyDownloadedFiles) : [],
          hasAccess: hasAccess,
          showAccessDeniedMessage: showAccessDeniedMessage,
          showRequestInProgressMessage: showRequestInProgressMessage,
        }));
      } catch (ex) {
        console.log(ex);
        setState((prevState) => ({
          ...prevState,
          hasAccess: false,
          initialLoader: false,
          showAccessDeniedMessage: true,
          showRequestInProgressMessage: false,
        }));
      }
    })();
  }, []);

  const handleBrandChange = (brand: string) => {
    let productsClone = cloneDeep(state.products);
    productsClone = productsClone.filter((value) => value["Brand"] == brand);
    const productOptions: IDropdownOption[] = [];
    productsClone.forEach((value: any) => {
      productOptions.push({ key: value["Title"], text: value["Title"] });
    });
    setState((prevState: IDocumentSearchAndDownloadState) => ({
      ...prevState,
      selectedBrand: brand,
      selectedProduct: null,
      productValues: productOptions,
    }));
  };

  const handleProdcutChange = (product: string) => {
    setState((prevState) => ({
      ...prevState,
      selectedProduct: product,
    }));
  };

  const handleTypeChange = (type: string) => {
    setState((prevState) => ({
      ...prevState,
      selectedType: type,
    }));
  };

  const handleMaterialChange = (material: string) => {
    setState((prevState) => ({
      ...prevState,
      selectedMaterial: material,
    }));
  };

  const handleSearchClick = () => {
    (async () => {
      try {
        setState((prevState) => ({
          ...prevState,
          showSpinner: true,
        }));

        const camlQuery = DocumentsSearchAndDownloadService.getCamlQueryForSearchFilters(
          state.selectedBrand,
          state.selectedProduct,
          state.selectedType,
          state.selectedMaterial,
          state.isVIP
        );
        const response = await props.dataProvider.getSTLFilesWithFilters(camlQuery);

        const items: any = DocumentsSearchAndDownloadService.generateListViewItems(
          response,
          state.alreadyDownloadedFiles,
          state.isVIP,
          state.approvedFileTypes,
          state.approvedProducts
        );
        setState((prevState) => ({
          ...prevState,
          showSpinner: false,
          viewItems: items["ViewItems"],
          allViewItems: items["AllViewItems"],
          allItems: items["AllItems"],
          downloadedItems: state.alreadyDownloadedFiles ? JSON.parse(state.alreadyDownloadedFiles) : [],
        }));
      } catch (ex) {
        console.log(ex);
        setState((prevState) => ({
          ...prevState,
          showSpinner: false,
        }));
      }
    })();
  };

  const handleDownloadClick = (selectedItems: any[]) => {
    (async () => {
      try {
        showSpinner(true);
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = String(today.getFullYear());

        const fileName = "STGC_ImplantDesignLibrary_" + dd + mm + yyyy + ".zip";
        var zip = new JSZip();
        let filesDownloaded: string[] = [];
        let tempDownloadedData: any[] = [];
        for (const item of selectedItems) {
          const response = await props.dataProvider.downloadFile(item["UniqueId"]);
          tempDownloadedData.push({
            FileUniqueId: item["UniqueId"],
          });
          if (item["Downloaded"] != "Yes") {
            filesDownloaded.push(item["UniqueId"] + "|" + item["FileVersion"] + "|" + item["Material"]);
          }

          if (response) {
            const file = JSON.parse(response);

            zip.file(file.FileDownloadName, file.FileContents, { base64: true });
          }
        }
        if (filesDownloaded.length > 0) {
          const updateDownloadReport = await props.dataProvider.updateFilesDownloadedReportList(filesDownloaded, props.currentUser.userId);
        }

        var itemClone: any[] = cloneDeep(state.viewItems);
        itemClone.forEach((item) => {
          const found: any[] = selectedItems.filter((sItem) => sItem["UniqueId"] == item["UniqueId"]);
          if (found.length > 0) {
            item["Downloaded"] = "Yes";
          }
        });

        zip.generateAsync({ type: "blob" }).then(function (zip) {
          saveAs(zip, fileName);
        });

        const downloadedFilesClone = cloneDeep(state.downloadedItems);
        downloadedFilesClone.push(...tempDownloadedData);

        setState((prevState) => ({
          ...prevState,
          showSpinner: false,
          viewItems: itemClone,
          allViewItems: itemClone,
          downloadedItems: downloadedFilesClone,
        }));
      } catch (ex) {
        showSpinner(false);
      }
    })();
  };

  const showSpinner = (value: boolean) => {
    setState((prevState) => ({
      ...prevState,
      showSpinner: value,
    }));
  };

  const handleFreeSearchChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {};

  const handleNewestDocumentClick = async () => {
    try {
      const documentsResponse = await props.dataProvider.getSTLFilesWithoutAnyFilters(state.isVIP ? "true" : "false");
      const alreadyDownloadedFiles = await props.dataProvider.getAlreadyDownloadedFiles(props.currentUser.userId);
      const items = DocumentsSearchAndDownloadService.generateListViewItems(
        documentsResponse,
        alreadyDownloadedFiles,
        state.isVIP,
        state.approvedFileTypes,
        state.approvedProducts
      );
      setState((prevState) => ({
        ...prevState,
        showSpinner: false,
        selectedBrand: null,
        selectedMaterial: null,
        selectedProduct: null,
        selectedType: null,
        viewItems: items["ViewItems"],
        allViewItems: items["ViewItems"],
        alreadyDownloadedFiles: alreadyDownloadedFiles,
        allItems: items["AllItems"],
        downloadedItems: alreadyDownloadedFiles ? JSON.parse(alreadyDownloadedFiles) : [],
      }));
    } catch (ex) {
      console.log(ex);
    }
  };
  return (
    <>
      {state.initialLoader && <Spinner size={SpinnerSize.large} ariaLive="assertive" />}
      {state.hasAccess && (
        <Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
          <Dialog
            hidden={!state.showSpinner}
            dialogContentProps={{
              type: DialogType.normal,
            }}
            modalProps={{ isBlocking: true, className: "busyOverlay" }}
          >
            <Spinner size={SpinnerSize.large} label="Working on it..." ariaLive="assertive" />
          </Dialog>

          {state.brandsLoaded && (
            <DocumentSearchFilters
              brands={state.brands}
              products={state.productValues}
              types={state.types}
              materials={state.materials}
              selectedBrand={state.selectedBrand}
              selectedProduct={state.selectedProduct}
              selectedType={state.selectedType}
              selectedMaterial={state.selectedMaterial}
              onBrandChange={(value) => handleBrandChange(value)}
              onProductChange={(value) => handleProdcutChange(value)}
              onTypeChange={(value) => handleTypeChange(value)}
              onMaterialChange={handleMaterialChange}
            ></DocumentSearchFilters>
          )}
          <DocumentListView
            items={state.viewItems}
            onDownloadClick={handleDownloadClick}
            allItems={state.allItems}
            downloadedItems={state.downloadedItems}
            onSearchClick={handleSearchClick}
            onNewestDocumentClick={handleNewestDocumentClick}
            freeSearch={state.freeSearch}
            onFreeSearchChange={handleFreeSearchChange}
          ></DocumentListView>
        </Stack>
      )}
      {state.showRequestInProgressMessage && (
        <Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
          <div style={{ paddingTop: "20%", textAlign: "center" }}>Your current request is in review state</div>
        </Stack>
      )}
      {state.showAccessDeniedMessage && (
        <Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
          <div style={{ paddingTop: "20%", textAlign: "center" }}>You do not have sufficient permissions to access this page</div>
        </Stack>
      )}
      {state.showAccessRequestRejectedMessage && (
        <Stack className={css("fullWidth", "proposalContainer")} verticalFill={true} tokens={{ childrenGap: 20 }}>
          <div style={{ paddingTop: "20%", textAlign: "center" }}>Your request to get the access has been rejected. Please create new request.</div>
        </Stack>
      )}
    </>
  );
};
