import * as React from "react";
import { Checkbox, css, TextField, Stack, PrimaryButton, Label } from "office-ui-fabric-react";
import "./../../Styles/Access.scss";
import { errorLabelStyles } from "../../Models/Constant";
import { IAskAccess } from "../../Models/IAskAccess";

interface IRequesterData {
	data: IAskAccess;
	onTextFieldChange: (fieldName: string, value: any) => void;
	onCheckBoxChange: (fieldName: string, checked: boolean) => void;
	readonly: boolean;
}

export const RequesterData = (props: IRequesterData) => {
	const { data, onTextFieldChange, onCheckBoxChange, readonly } = props;
	return (
		<>
			<div className="row">
				<div className={css("column6")}>
					<TextField label="Name" required value={data?.Name ? data.Name.value : ""} disabled />
				</div>
				<div className={css("column6")}>
					<TextField
						label="Company"
						required
						onChange={(e, v) => onTextFieldChange("Company", v)}
						value={data?.Company ? data.Company.value : ""}
						errorMessage={data?.Company.valid ? null : "Company is required"}
						disabled={readonly}
					/>
				</div>
			</div>
			<div className="row">
				<div className={css("column6")}>
					<TextField label="Email" required value={data?.EMail ? data.EMail.value : ""} disabled />
				</div>
				<div className={css("column6")} />
			</div>

			<div className="row">
				<div className={css("column12")}>
					<TextField
						label="Reason"
						required
						multiline
						rows={6}
						onChange={(e, v) => onTextFieldChange("Reason", v)}
						value={data?.Reason ? data.Reason.value : ""}
						errorMessage={data?.Reason.valid ? null : "Reason is required"}
						disabled={readonly}
					/>
				</div>
			</div>

			<div className="row">
				<div className={css("column8")}>
					<Checkbox
						label="I understand and agree that nothing can be construed as any obligation on Straumann’s side to enter into any agreement and Straumann expressly reserves its right not to enter into an agreement with me and/or the party I represent. The agreement between the parties is not valid until legally signed by both parties."
						checked={data?.IsAgreed ? (data?.IsAgreed?.value == "Yes" ? true : false) : false}
						onChange={(e, v) => onCheckBoxChange("IsAgreed", v)}
						styles={data?.IsAgreed.valid ? null : { checkbox: { borderColor: "rgb(164, 38, 44)" } }}
					/>
					{!data?.IsAgreed.valid && (
						<Label
							styles={{
								root: errorLabelStyles,
							}}
						>
							Please click on "I agree..."
						</Label>
					)}
				</div>
				<div className={css("column6")} />
				<div className={css("column4")} />
			</div>
		</>
	);
};
