import * as React from "react";
import { css, PrimaryButton, Label } from "office-ui-fabric-react";
import "./../../Styles/Access.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { errorLabelStyles } from "../../Models/Constant";

interface ICaptchaAndSubmitActionProps {
	isCaptchaValid: boolean;
	onRecaptchaToken: (token: string) => void;
	onButtonClicked: (event: any) => void;
	buttonTitle: string;
	isSubmitProcessing: boolean;
	refReCaptchaRegister: any;
}

export const CaptchaAndSubmitAction = (props: ICaptchaAndSubmitActionProps) => {
	const { onRecaptchaToken, isCaptchaValid, onButtonClicked, isSubmitProcessing, refReCaptchaRegister, buttonTitle } = props;
	return (
		<div className="row">
			<div className={css("column6")} />
			<div className={css("column3")} style={{ textAlign: "right", paddingLeft: 50 }}>
				<ReCAPTCHA
					sitekey="6LfB5BQgAAAAABaHfIoaG-gU0_WFjZggiNzNTLnP"
					onChange={(token: string) => {
						onRecaptchaToken(token);
					}}
					ref={refReCaptchaRegister}
					onExpired={() => onRecaptchaToken("")}
					onErrored={() => onRecaptchaToken("")}
				/>
				{!isCaptchaValid && (
					<Label
						styles={{
							root: errorLabelStyles,
						}}
					>
						Please click on "I'm not a robot"
					</Label>
				)}
			</div>
			<div className={css("column3")} style={{ textAlign: "right" }}>
				<PrimaryButton text={buttonTitle} onClick={onButtonClicked} disabled={isSubmitProcessing} />
			</div>
		</div>
	);
};
