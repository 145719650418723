import React, { Component } from "react";
import { GoogleLogin, GoogleLogout, useGoogleLogout } from "react-google-login";
import { AccountType, IAuthenticatedUser, IUserAccount } from "./LoginComponent";
import Button from "react-bootstrap/Button";
import "./GoogleComponent.css";
import { Redirect } from "react-router";

import { loadGapiInsideDOM, loadAuth2 } from "gapi-script";
import { Dialog, DialogType, Label, Spinner, SpinnerSize } from "office-ui-fabric-react";

const CLIENT_ID = "313235099716-c5ttur4f2v0o001gd3vngd55kmhirihm.apps.googleusercontent.com";

export interface IGoogleLoginComponentProps {
	currentUser: IAuthenticatedUser;
	//signedIn: boolean;
	callback: (userInformation: IUserAccount, token: string, isRSMToken: boolean) => Promise<IAuthenticatedUser>;
	showError: (error: string) => void;
	setAuthStatus: (loggedIn: boolean, user: IAuthenticatedUser) => Promise<void>;
}

export interface IGoogleLoginComponentState {
	redirectConsent: boolean;
	showWaitingSpinner: boolean;
	showGoogleError: boolean;
	googleErrorMessage: string;
}

export default class GoogleLoginComponent extends Component<IGoogleLoginComponentProps, IGoogleLoginComponentState> {
	constructor(props: IGoogleLoginComponentProps) {
		super(props);

		console.log("GAPI", (window as any).gapi);
		this.state = {
			redirectConsent: false,
			showWaitingSpinner: false,
			showGoogleError: false,
			googleErrorMessage: null,
		};
	}

	// Success Handler
	private responseGoogleSuccess = (response: any) => {
		console.log("GOOGLE RESPONSE", response);
		let user: IUserAccount = {
			firstName: response.profileObj.givenName,
			lastName: response.profileObj.familyName,
			email: response.profileObj.email,
			accountType: AccountType.Google,
			name: response.profileObj.name,
			userId: null,
			activateGuid: null,
			id: null,
			password: null,
		} as IUserAccount;

		this.setState({ showWaitingSpinner: true });

		if (!this.props.currentUser || this.props.currentUser.email !== user.email) {
			this.props
				.callback(user, response.tokenId, false)
				.then((spUser) => {
					if (spUser.isNewUser) {
						// keep user information for consent screen
						window.sessionStorage.rsmConsentTarget = JSON.stringify(spUser);

						// redirect user to consent page first
						this.setState({ showWaitingSpinner: false, redirectConsent: true });

						// for now, don't sign user in yet
						window.sessionStorage.removeItem("authToken");
						window.sessionStorage.removeItem("stlUser");
					} else {
						// directly authenticate user
						this.setState({ showWaitingSpinner: false });
						this.props.setAuthStatus(true, spUser);
					}
				})
				.catch(() => {
					this.setState({ showWaitingSpinner: false });
					this.props.showError("Google");
					this.forceLogout();
				});
		}
		// this.setState({ currentUser: user, isLoggedIn: true }, () => {
		//     this.props.setAuthStatus(true, user);
		// });
	};

	// Error Handler
	private responseGoogleError = (response: any) => {
		console.log(response);
		if (response.error == "popup_closed_by_user") {
			console.log("Google login cancelled by user");
		} else {
			this.setState({
				showGoogleError: true,
				googleErrorMessage: `Google SignIn error: ${response.error}`,
			});
			//alert("Error on Google login: " + response.details)
		}
	};

	// Logout Session and Update State
	private logout = () => {
		if (this.props.currentUser) {
			this.props.setAuthStatus(false, null);
		}
	};

	private forceLogout = () => {
		console.log("Forcing logout...");
		loadGapiInsideDOM().then((gapi) => {
			console.log("Gapi loaded....", gapi);

			gapi.load("auth2", function () {
				console.log("auth2 loaded", (window as any).gapi.auth2);
				gapi.auth2.getAuthInstance().then((auth2: any) => {
					auth2.signOut().then(() => {
						console.log("signed out.");
					});
				});
			});
		});

		window.sessionStorage.removeItem("authToken");
		window.sessionStorage.removeItem("stlUser");
	};

	public render() {
		let isLoggedIn = Boolean(this.props.currentUser);
		let userName = this.props.currentUser ? `${this.props.currentUser.firstName} ${this.props.currentUser.lastName}` : "";

		return (
			<div>
				{this.state.redirectConsent && <Redirect to="consent" />}
				<div>
					{isLoggedIn ? (
						<div>
							Signed in as:
							<br />
							{userName}
							<br />
							<GoogleLogout
								clientId={CLIENT_ID}
								className="googleLogoutButton btn btn-secondary"
								buttonText={"Logout"}
								onLogoutSuccess={this.logout}
							></GoogleLogout>
						</div>
					) : (
						<GoogleLogin
							className="googleButton"
							clientId={CLIENT_ID}
							buttonText="Sign in with Google"
							onSuccess={this.responseGoogleSuccess}
							onFailure={this.responseGoogleError}
							isSignedIn={true}
							cookiePolicy={"single_host_origin"}
						/>
					)}
				</div>
				<Dialog
					hidden={!this.state.showWaitingSpinner}
					dialogContentProps={{
						type: DialogType.normal,
					}}
					modalProps={{ isBlocking: true, className: "busyOverlay" }}
				>
					<Spinner size={SpinnerSize.large} label="Checking Google Account..." ariaLive="assertive" />
				</Dialog>
				<Dialog
					hidden={!this.state.showGoogleError}
					dialogContentProps={{
						type: DialogType.normal,
					}}
					modalProps={{ isBlocking: true, className: "busyOverlay" }}
					onDismiss={() => {
						this.setState({ googleErrorMessage: null, showGoogleError: false });
					}}
				>
					<Label style={{ color: "red" }}>{this.state.googleErrorMessage}</Label>
				</Dialog>
			</div>
		);
	}
}
